export const toFormData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  form: FormData = new FormData(),
  namespace: string = ''
): FormData => {
  let formKey: string;

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property) && obj[property]) {
      formKey = namespace ? `${namespace}[${property}]` : property;

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        form.append(formKey, obj[property].toISOString());
      } else if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        toFormData(obj[property], form, formKey);
      } else {
        // if it's a string or a File object
        form.append(formKey, obj[property]);
      }
    }
  }

  return form;
};
