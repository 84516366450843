import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MailDetails from './MailDetails';
import MailList from './MailList';
import MailSidebar from './MailSidebar';
import ContactList from 'src/components/ProntoHousingAdministration/Contacts/list/ContactList';
import SelectCertificationsDialog from './SelectCertificationsDialog';
import { useDispatch, useSelector } from 'src/redux/store';
import { shallowEqual } from 'react-redux';
import {
  cleanFilter,
  getFullConversation,
  getSMSConversations,
  setSelectedConversation,
} from 'src/redux/slices/conversationsV2';
import { COMPLIANCE_PATHS } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import FaxCompose from './FaxCompose';
import FaxDetails from './FaxDetails';
import { EXPAND_CONVERSATION, FIELDS_CONVERSATION } from './utils';
import SmsDetail from './SmsDetail/SmsDetail';
import MessageCompose from './MessageCompose';

export const EMAIL_TYPE = 1;
export const SMS_TYPE = 2;
export const FAX_TYPE = 3;

const ConversationsV2 = ({ isProperty, isUserDashboard }) => {
  const { conversationKey, certification_id, property_slug } = useParams();
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const labels = useSelector(
    ({ conversationsV2: { labels } }) => labels,
    shallowEqual
  );

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSelectCertification, setOpenSelectCertification] = useState(false);
  const [openCompose, setOpenCompose] = useState(false);
  const [openFaxCompose, setOpenFaxCompose] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [showContacts, setShowContacts] = useState(false);

  const showEmailDetail = useMemo(
    () =>
      !showContacts &&
      conversationKey &&
      selectedConversation?.conversation_type === EMAIL_TYPE &&
      selectedConversation !== null,
    [conversationKey, showContacts, selectedConversation]
  );

  const showSmsDetail = useMemo(
    () =>
      !showContacts &&
      conversationKey &&
      selectedConversation?.conversation_type === SMS_TYPE &&
      selectedConversation !== null,
    [conversationKey, showContacts, selectedConversation]
  );

  const showFaxDetail = useMemo(
    () =>
      !showContacts &&
      conversationKey &&
      selectedConversation?.conversation_type === FAX_TYPE &&
      selectedConversation !== null,
    [conversationKey, showContacts, selectedConversation]
  );

  const returnPath = useMemo(
    () =>
      certification_id
        ? `${COMPLIANCE_PATHS.root}/${property_slug}/${certification_id}`
        : `${COMPLIANCE_PATHS.root}/${property_slug}`,
    [certification_id, property_slug]
  );

  const propertyId = useSelector(
    (st) => st?.property?.property?.id,
    shallowEqual
  );

  const handleOpenCompose = useCallback(
    (isReply = false, isForward = false) => {
      if (!isReply && !isForward) {
        dispatch(
          setSelectedConversation({
            conversation: null,
            conversationHadNewMessages: false,
          })
        );
        if (!isUserDashboard) {
          navigate(returnPath);
        }
      }
      setOpenCompose(true);
      setIsReply(isReply);
      setIsForward(isForward);
    },
    [dispatch, navigate, returnPath, isUserDashboard]
  );

  const handleOpenFaxCompose = () => {
    setOpenFaxCompose(true);
  };

  const toggleContactsShow = (status) => {
    setShowContacts(status);
  };

  const handleSelectCertification = () => {
    setOpenSelectCertification(true);
  };

  const closeFaxCompose = () => {
    setOpenFaxCompose(false);
  };

  const fetchFullConversation = useCallback(
    (showLoader = true) => {
      dispatch(
        getFullConversation(
          conversationKey,
          {
            expand: EXPAND_CONVERSATION,
            fields: FIELDS_CONVERSATION,
          },
          showLoader
        )
      );
    },
    [conversationKey, dispatch]
  );

  // get sms conversations to validate if there are existing conversations
  // for a created conversation
  useEffect(() => {
    let filterBy = certification_id ? 'certification' : 'property';
    let filterValue = certification_id ? certification_id : propertyId;

    if (isUserDashboard) {
      filterBy = 'as_specialist';
      filterValue = 'true';
    }

    dispatch(
      getSMSConversations(filterValue, filterBy, {
        fields: [
          'from_to_pair',
          'id',
          'twilio_credentials.applicant_pool',
          'twilio_credentials.property',
        ],
        expand: ['twilio_credentials'],
        ...(isUserDashboard
          ? { as_specialist: true, file_owner: user?.id }
          : {}),
      })
    );
  }, [certification_id, dispatch, propertyId, isUserDashboard, user?.id]);

  useEffect(() => {
    if (conversationKey && !selectedConversation?.id) {
      fetchFullConversation();
    }
  }, [conversationKey, fetchFullConversation, selectedConversation]);

  useEffect(() => {
    if (selectedConversation?.id) {
      setOpenSidebar(false);
    }
  }, [selectedConversation]);

  // reset to the emails filter when the component is unmounted
  useEffect(() => {
    return () => {
      dispatch(cleanFilter());
    };
  }, [dispatch, labels]);

  return (
    <Card
      sx={{
        height: { md: '72vh' },
        display: { md: 'flex' },
      }}
    >
      <MailSidebar
        isOpenSidebar={openSidebar}
        isProperty={isProperty}
        onCloseSidebar={() => setOpenSidebar(false)}
        onOpenCompose={handleOpenCompose}
        onOpenFaxCompose={handleOpenFaxCompose}
        toggleContact={toggleContactsShow}
        isUserDashboard={isUserDashboard}
        handleSelectCertification={handleSelectCertification}
      />

      {showContacts && <ContactList conversationMode={true} />}
      {showEmailDetail && <MailDetails handleOpenCompose={handleOpenCompose} />}
      {showFaxDetail && <FaxDetails />}
      {showSmsDetail && <SmsDetail />}

      {!showEmailDetail &&
        !showFaxDetail &&
        !showContacts &&
        !showSmsDetail && (
          <MailList
            composeIsOpen={openCompose || openFaxCompose}
            onOpenSidebar={() => setOpenSidebar(true)}
            isUserDashboard={isUserDashboard}
          />
        )}

      <MessageCompose
        isOpenCompose={openCompose}
        isReply={isReply}
        isForward={isForward}
        setIsReply={setIsReply}
        onCloseCompose={() => setOpenCompose(false)}
        isProperty={isProperty}
      />

      <FaxCompose
        isOpenCompose={openFaxCompose}
        onCloseCompose={closeFaxCompose}
      />

      <SelectCertificationsDialog
        open={openSelectCertification}
        setOpen={setOpenSelectCertification}
        onOpenCompose={handleOpenCompose}
        onOpenFaxCompose={handleOpenFaxCompose}
        type={selectedFilter.filters.conversation_type}
        isProperty={isProperty}
        propertyId={propertyId}
      />
    </Card>
  );
};

ConversationsV2.defaultProps = {
  isProperty: false,
};

export default ConversationsV2;
