import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import emailFill from '@iconify/icons-eva/email-fill';
import inboxFill from '@iconify/icons-eva/inbox-fill';
import smsFill from '@iconify/icons-eva/message-square-outline';
import folderRemoveFill from '@iconify/icons-eva/folder-remove-fill';
import bookIcon from '@iconify/icons-ic/book';
import fax16Filled from '@iconify/icons-fluent/fax-16-filled';
// material
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  FILTER_IDS,
  setSelectedFilter,
} from 'src/redux/slices/conversationsV2';
import { useLocation, useNavigate, useParams } from 'react-router';
import { COMPLIANCE_PATHS } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const LABEL_ICONS = {
  all: emailFill,
  inbox: inboxFill,
  sms: smsFill,
  unassigned: folderRemoveFill,
  contacts: bookIcon,
  fax: fax16Filled,
};

const MailSidebarItem = ({ label, hideContacts, ...other }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { certification_id, property_slug, conversationKey } = useParams();
  const { search, pathname } = useLocation();
  const isUserDashboard = pathname.startsWith('/workbench/user');
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );
  const [smsNotAvailable, setSMSNotAvailable] = useState(false);

  const isSMS = useMemo(() => label.name === 'sms', [label.name]);

  useEffect(() => {
    const getTwilioCredentialRegistrationStatus = async () => {
      let smsIsNotAvailable = false;
      if (certification_id) {
        const { data: credentialData } = await axios.get(
          `certification/certification/${certification_id}?expand=applicant_pool.twilio_credentials&fields=applicant_pool.twilio_credentials.twilio_phone_registration_status_successful`
        );
        smsIsNotAvailable =
          !credentialData?.applicant_pool?.twilio_credentials?.[0]
            ?.twilio_phone_registration_status_successful;
      }

      setSMSNotAvailable(smsIsNotAvailable);
    };

    if (isSMS) {
      getTwilioCredentialRegistrationStatus();
    }
  }, [isSMS, certification_id]);

  const isActive = useMemo(
    () => selectedFilter?.id === label?.id,
    [label?.id, selectedFilter]
  );
  const isUnread = useMemo(() => label?.unreadCount > 0, [label?.unreadCount]);

  const returnPath = useMemo(
    () =>
      !isUserDashboard
        ? certification_id
          ? `${COMPLIANCE_PATHS.root}/${property_slug}/${certification_id}${search}`
          : `${COMPLIANCE_PATHS.root}/${property_slug}${search}`
        : `${COMPLIANCE_PATHS.root}/user/communications/${search}${
            search.startsWith('?') ? '&' : '?'
          }as_specialist=true&compliance_user=${user?.id}`,
    [certification_id, property_slug, search, isUserDashboard, user?.id]
  );

  const updateParams = useCallback(() => {
    // if the user is on the detail of a conversation is returned to the
    // conversations list
    // hide contact list  when user clicks on any item on the list
    hideContacts();
    if (conversationKey) {
      navigate(returnPath);
    }

    if (selectedFilter?.id === label?.id) {
      return;
    }

    dispatch(
      setSelectedFilter({
        id: label?.id || FILTER_IDS.all,
        filters:
          {
            ...label?.filters,
            ...(isUserDashboard
              ? { as_specialist: true, file_owner: user?.id }
              : {}),
          } || {},
      })
    );
  }, [
    selectedFilter?.id,
    label?.id,
    label?.filters,
    hideContacts,
    conversationKey,
    dispatch,
    isUserDashboard,
    user?.id,
    navigate,
    returnPath,
  ]);

  return (
    <ListItem
      button
      onClick={isSMS && smsNotAvailable ? undefined : updateParams}
      sx={{
        px: 3,
        height: 48,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: label?.uppercase ? 'uppercase' : 'capitalize',
        ...(isActive && {
          color: 'text.primary',
          fontWeight: 'fontWeightMedium',
          bgcolor: 'action.selected',
        }),
        ...(smsNotAvailable && {
          color: 'text.disabled',
        }),
        cursor: smsNotAvailable ? 'default' : 'pointer',
      }}
      disableRipple={smsNotAvailable}
      {...other}
    >
      <ListItemIcon>
        <Icon
          icon={LABEL_ICONS[label.id]}
          style={{ color: label.color }}
          width={24}
          height={24}
        />
      </ListItemIcon>

      {isSMS && smsNotAvailable ? (
        <Tooltip
          arrow
          placement={'right'}
          title={
            'SMS is not available until the number for this applicant pool is registered.'
          }
        >
          <ListItemText disableTypography primary={label.name || ''} />
        </Tooltip>
      ) : (
        <ListItemText disableTypography primary={label.name || ''} />
      )}

      {isUnread && (
        <Typography variant="caption">{label?.unreadCount}</Typography>
      )}
    </ListItem>
  );
};

MailSidebarItem.propTypes = {
  label: PropTypes.object.isRequired,
};

export default memo(MailSidebarItem);
