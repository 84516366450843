import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import FCFileItem from './FCFileItem';
import { cloneDeep } from 'lodash';
import {
  closestCorners,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Label from 'src/components/Label';

export default function FCSelectFiles({
  certificationFile,
  setFieldValue,
  values,
  touched,
  errors,
}) {
  const [filesOptions, setFilesOptions] = useState([]);
  const sensors = useSensors(useSensor(PointerSensor));
  const [activeFile, setActiveFile] = useState(null);
  const [iterableFiles, setIterableFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = (ix) => {
    const selectedFiles = cloneDeep(values.files);
    selectedFiles.splice(ix, 1);
    setFieldValue('files', selectedFiles);
  };

  const handleDragCancel = () => {
    setActiveFile(null);
  };

  const handleDragStart = (event) => {
    setActiveFile(event.active.data.current);
  };

  const handleDragEnd = useCallback(
    (event) => {
      setActiveFile(null);
      const selectedFiles = cloneDeep(values.files);
      const { active, over } = event;
      if (active.id !== over.id) {
        const overIndex = selectedFiles.findIndex(
          (_file) => _file?.id === over.data.current.id
        );
        const activeIndex = selectedFiles.findIndex(
          (_file) => _file?.id === active.data.current.id
        );

        if (overIndex > activeIndex) {
          selectedFiles.splice(overIndex + 1, 0, active.data.current);
          selectedFiles.splice(activeIndex, 1);
        } else {
          selectedFiles.splice(overIndex, 0, active.data.current);
          selectedFiles.splice(activeIndex + 1, 1);
        }

        setFieldValue('files', selectedFiles);
      }
    },
    [setFieldValue, values.files]
  );

  useEffect(() => {
    setIterableFiles(values.files);
  }, [values.files]);

  useEffect(() => {
    setIsLoading(true);
    const getFiles = async () => {
      const { data } = await axiosInstance.get(
        `certification/document/?certification_file=${certificationFile}&expand=household_members&fields=id,title,answer_group_subject,household_members.last_name,household_members.first_name`
      );
      if (data.length) {
        setFilesOptions(data);
        setIsLoading(false);
      }
    };
    void getFiles();
  }, [certificationFile]);

  return (
    <Box sx={{ m: 2 }}>
      <Autocomplete
        fullWidth
        isOptionEqualToValue={(opt, val) => {
          return +val?.id === +opt?.id;
        }}
        loading={isLoading}
        value={[]}
        getOptionDisabled={(option) =>
          !!values.files.find((file) => file.id === option.id)
        }
        multiple
        options={filesOptions}
        noOptionsText={'There are no files in File manager.'}
        filterOptions={(x, state) => {
          if (state.inputValue) {
            return x.filter((item) =>
              item.title.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          } else {
            return x;
          }
        }}
        getOptionLabel={(opt) => {
          return opt?.title || '';
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  px: 1,
                  py: 0.5,
                }}
              >
                <Typography variant="body1">{option.title}</Typography>
                {option.answer_group_subject && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Label variant="ghost" color={'default'}>
                      {option.answer_group_subject}
                    </Label>
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {option.household_members.map((member) => (
                    <Label key={member.id} variant="ghost" color={'default'}>
                      {member.first_name} {member.last_name}
                    </Label>
                  ))}
                </Box>
                <Typography variant="caption" color={'text.secondary'}>
                  {option.name} {option.unit && `• ${option.unit.name}`}
                </Typography>
              </Box>
            </li>
          );
        }}
        onChange={(event, value) => {
          setFieldValue('files', [...values.files, ...value]);
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              size="small"
              placeholder={'Select from File Manager'}
            />
            {Boolean(touched.files && errors.files) && (
              <FormHelperText sx={{ pl: 3 }} error>
                {errors.files}
              </FormHelperText>
            )}
          </>
        )}
      />
      {!!values.files.length && (
        <Box sx={{ mt: 4 }}>
          <Typography variant={'caption'} color={'text.secondary'}>
            <b>FILES</b>
          </Typography>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragCancel={handleDragCancel}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={iterableFiles}
              strategy={verticalListSortingStrategy}
            >
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {values.files.map((file, ix) => (
                  <FCFileItem
                    key={'file_' + file.id}
                    file={file}
                    title={file.title}
                    index={ix}
                    handleDelete={handleDelete}
                    dragging={Boolean(activeFile)}
                    iterableFiles={iterableFiles}
                  />
                ))}
              </Grid>
            </SortableContext>
          </DndContext>
        </Box>
      )}
    </Box>
  );
}
