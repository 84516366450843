import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { shallowEqual } from 'react-redux';
import { setUpdate } from 'src/redux/slices/conversationsV2';
// hooks
import useAuth from 'src/hooks/useAuth';
// utils
import axiosInstance from 'src/utils/axios';
import { fDateUTC } from 'src/utils/formatTime';

export default function CertificationsDialog({ open, setOpen, email }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const conversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const { selectedProperty } = useSelector((state) => state.property);
  const { update } = useSelector((state) => state.conversationsV2);

  const [selectedCertification, setSelectedCertification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [contactsName, setContactsName] = useState({
    ...conversation.from
      .filter((u) => !u.isHHM && u?.id)
      .reduce((a, v) => ({ ...a, [v.id]: { ...v } }), {}),
  });
  const [certificationOptions, setCertificationOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');

  const isUserDashboard = pathname.startsWith('/workbench/user');
  // In the future isContact can be changed to false and then the compliance can create a secondary email for a hm.
  const isContact = true;

  const getCertifications = async () => {
    const getProperty = isUserDashboard
      ? `as_specialist=true&compliance_user=${user?.id}&`
      : `property=${conversation?.property?.id || selectedProperty?.id}&`;

    const expand = ['property', 'household.household_members.member_type'];
    const fields = [
      'id',
      'display_name',
      'effective_date',
      'property.slug',
      'property.id',
      'household.id',
      'household.name',
      'household.household_members.id',
      'household.household_members.first_name',
      'household.household_members.last_name',
      'household.household_members.member_type.display_name',
      'household.household_members.user',
    ];

    const response = await axiosInstance.get(
      `certification/certification/?${getProperty}&search=${searchFilter}&expand=${expand.join(
        ','
      )}&fields=${fields.join(',')}&page_size=5&page=1`
    );
    if (response.status === 200) {
      const certificationDisplayOptions = [];
      response.data.results.forEach((certification) => {
        const option = {
          id: certification.id,
          display_name: `${certification?.household?.name || ''} | ${
            certification?.display_name || ''
          }`,
          household_id: certification?.household?.id,
          effective_date: certification?.effective_date,
          household_members: [],
          property_slug: certification?.property?.slug || null,
        };
        certification?.household.household_members?.forEach((member) => {
          const memberInfo = {
            id: member.id,
            display_name: `${member.first_name} ${member.last_name}`,
            member_type: member.member_type,
            email: member.email,
            user_id: member.user,
          };
          option.household_members.push(memberInfo);
        });
        certificationDisplayOptions.push(option);
      });
      setCertificationOptions(certificationDisplayOptions);
    }
    setIsLoading(false);
  };

  const getHouseholdMembers = () => {
    setMemberOptions(selectedCertification?.household_members);
  };

  const readyToSend = () => {
    if (isContact) {
      return Boolean(selectedCertification?.id);
    } else {
      return Boolean(selectedCertification?.id) && Boolean(selectedMember?.id);
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedCertification(null);
    setSelectedMember(null);
  };

  const replaceEmail = async () => {
    try {
      // Patch to HM email
      const response = await axiosInstance.patch(
        `household/household_member/${selectedMember.id}/`,
        { email: conversation.messages[0].from_email }
      );
      if (response.status === 200 || response.status === 201) {
        const responseConversation = await axiosInstance.patch(
          `communication/new_conversation/conversation/${conversation.id}/`,
          {
            certification: selectedCertification.id,
            household_members_users: [selectedMember.user_id],
          }
        );
        if (
          responseConversation.status === 200 ||
          responseConversation.status === 201
        ) {
          const responseMessage = await axiosInstance.patch(
            `communication/new_conversation/message/${conversation.messages[0].id}/`,
            { sender_user: selectedMember.user_id }
          );
          if (
            responseMessage.status === 200 ||
            responseMessage.status === 201
          ) {
            enqueueSnackbar('Email replaced correctly.', {
              variant: 'success',
            });
            dispatch(setUpdate(!update));
            onClose();
          }
        } else {
          console.error('There was an error adding to certification.');
          enqueueSnackbar(
            'There was an error adding conversation to certification.',
            { variant: 'error' }
          );
        }
      } else {
        console.error('There was an error replacing the email.');
        enqueueSnackbar('There was an error replacing the email.', {
          variant: 'error',
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('There was an error replacing the email.', {
        variant: 'error',
      });
    }
  };

  const newContact = async () => {
    // get the list of contacts available on the conversation
    const contacts = conversation.from.filter((u) => !u.isHHM && u?.id);
    const hhmIds = conversation.from.filter((u) => u.isHHM && u?.id);

    // using the list of contacts build the HTTP requests to PATCH the fields
    // first_name and last_name if the user wants to change these fields
    const requests = contacts.map((contact) => {
      const body = {
        first_name: contactsName[contact.id].name || null,
        last_name: contactsName[contact.id].lastName || null,
      };
      return axiosInstance.patch(
        `contacts/${contactsName[contact.id]?.id}/`,
        body
      );
    });

    // Call the HTTP requests
    const response = await Promise.all(requests);

    // build the HTTP request to patch the conversation, this PATCH the certification field
    // and the contacts field
    const responseConversation = await axiosInstance.patch(
      `communication/new_conversation/conversation/${conversation.id}/`,
      {
        certification: selectedCertification.id,
        contacts: (response || []).map((resp) => resp.data.id),
        household_members_users: hhmIds.map((hhm) => hhm.id),
      }
    );

    if (
      [200, 201].includes(responseConversation.status) &&
      selectedCertification?.property_slug
    ) {
      navigate(
        `/workbench/${selectedCertification.property_slug}/${selectedCertification.id}/communications/${conversation.id}/?t1=communications`
      );
    }
  };

  const handleSend = () => {
    if (isContact) {
      void newContact();
    } else {
      void replaceEmail();
    }
  };

  useEffect(() => {
    void getCertifications();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (searchFilter !== null) {
      const timeOutId = setTimeout(() => {
        setIsLoading(true);
        void getCertifications();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedCertification?.id) {
      setSelectedMember(null);
      getHouseholdMembers();
    } else {
      setSelectedMember(null);
      setMemberOptions([]);
    }
  }, [selectedCertification]); // eslint-disable-line

  useEffect(() => {
    setSelectedMember(null);
  }, [isContact]); // eslint-disable-line

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select a Certification</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption">
          {`Relate message from <${email || ''}> to a certification.`}
        </DialogContentText>
        <Autocomplete
          freeSolo
          fullWidth
          disabled={isLoading}
          loading={isLoading}
          options={certificationOptions}
          getOptionLabel={(option) =>
            `${option.display_name} ${
              option?.effective_date
                ? `| ${fDateUTC(option.effective_date)}`
                : ''
            }`
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedCertification(newValue);
          }}
          onInputChange={(event, value) => {
            setSearchFilter(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Certification" />
          )}
          renderOption={(props, option) => (
            <Box
              {...props}
              key={option.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, mb: 0, width: '100%' }}
              >
                {option.display_name}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ width: '100%' }}
              >
                <b>Effective Date: </b>{' '}
                {option?.effective_date
                  ? fDateUTC(option.effective_date)
                  : 'N/A'}
              </Typography>
            </Box>
          )}
        />

        <Tooltip
          title={
            !selectedCertification?.id
              ? 'You need to select a Certification'
              : ''
          }
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {Object.values(contactsName)?.length > 0 && (
              <>
                <Divider sx={{ mt: 3 }} />
                <Typography variant="body2" color="text.secondary">
                  <b>Contact Information</b>
                </Typography>
              </>
            )}

            {Object.values(contactsName).map((contact) => {
              return (
                <>
                  <em>{contact.email}</em>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <TextField
                      fullWidth
                      label="First Name"
                      disabled={!selectedCertification?.id}
                      onChange={(event) => {
                        setContactsName((prev) => {
                          const data = { ...prev };
                          data[contact.id].name = event.target.value;
                          return data;
                        });
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Last Name"
                      disabled={!selectedCertification?.id}
                      onChange={(event) => {
                        setContactsName((prev) => {
                          const data = { ...prev };
                          data[contact.id].lastName = event.target.value;
                          return data;
                        });
                      }}
                    />
                  </Box>
                </>
              );
            })}
          </Box>
        </Tooltip>

        {!isContact && (
          <Tooltip
            title={
              !selectedCertification?.id
                ? 'You need to select a Certification'
                : ''
            }
          >
            <Autocomplete
              fullWidth
              sx={{ pt: 2 }}
              value={selectedMember}
              disabled={!selectedCertification?.id}
              options={memberOptions}
              getOptionLabel={(option) => option.display_name}
              onChange={(event, newValue) => {
                setSelectedMember(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Household Member" />
              )}
            />
          </Tooltip>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!readyToSend()}
          onClick={() => handleSend()}
        >
          {isContact ? 'Add To Certification' : 'Replace Email'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
