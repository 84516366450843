import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import triggerFileDownload from 'src/utils/triggerFileDownload';
import { useSnackbar } from 'notistack';
import DMEditAttachmentPopOver from './EditAttachmentPopOver';
// material
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify/icons-eva/close-outline';
// utils
import axiosInstance from 'src/utils/axios';
import { extractPDFPages, MODES } from './utils';
import { objToFormData } from '../DocumenManagerV2/utils';
import TreeTraceback from './TreeTraceback';

// -----------------------------------------------------------------------

const CDMMainMode = ({
  pdfInstance,
  dmState,
  selectedPages,
  updateDmState,
  handleClose,
  selectCertifs,
  selectCertification,
  readOnly = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleActionMode = async (actionMode) => {
    try {
      setLoading(actionMode);

      if (
        !pdfInstance ||
        !dmState.core ||
        !dmState.annotationManager ||
        !dmState.fileBlob
      ) {
        enqueueSnackbar('Something went wrong with the pdf instance.', {
          variant: 'error',
        });
        return;
      }

      const pagesToExtract = pdfInstance.UI.getSelectedThumbnailPageNumbers();

      if (!pagesToExtract.length > 0) {
        enqueueSnackbar('No pages selected.', { variant: 'error' });
        return;
      }

      const coreDocument = await dmState.core?.createDocument(dmState.fileBlob);

      const actionFile = await extractPDFPages({
        annotationManager: dmState.annotationManager,
        coreDocument: coreDocument,
        pagesToExtract: pagesToExtract,
      });

      const newState = {
        actionMode: actionMode,
        actionFile: actionFile,
        pagesToExtract: pagesToExtract,
        pagesInput: null,
      };

      const pagesInput =
        dmState.viewerRef.current.children[0].contentWindow.document.querySelector(
          'input.pagesInput'
        );
      const thumbCloseMultiSelect =
        dmState.viewerRef.current.children[0].contentWindow.document.querySelector(
          'button[data-element=thumbCloseMultiSelect]'
        );

      if (pagesInput) {
        newState.pagesInput = pagesInput.value;
      }

      updateDmState(newState);

      if (thumbCloseMultiSelect) {
        thumbCloseMultiSelect.click();
      }

      if (pdfInstance) {
        pdfInstance.UI.selectThumbnailPages(pagesToExtract);
        pdfInstance.UI.disableFeatures([
          'ThumbnailMultiselect',
          'ThumbnailReordering',
          'ThumbnailMerging',
        ]);
        pdfInstance.Core.documentViewer.enableReadOnlyMode();
        pdfInstance.Core.annotationManager.enableReadOnlyMode();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    } finally {
      setLoading(null);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      if (dmState.conversationId && dmState.fileBlob) {
        const formData = objToFormData({ pdf_file_blob: dmState.fileBlob });
        const response = await axiosInstance.patch(
          `communication/new_conversation/conversation/${dmState.conversationId}/`,
          formData
        );
        if (response.status === 200) {
          updateDmState({
            isDirty: false,
            newPdfFileUrl: response.data?.pdf_file_url || null,
          });
          enqueueSnackbar('File saved successfully.', { variant: 'success' });
          if (selectCertification) {
            selectCertification();
          }
        } else {
          enqueueSnackbar('Error saving the file.', { variant: 'error' });
        }
      } else {
        console.error('fileBlob', dmState.fileBlob);
        console.error('conversationId', dmState.conversationId);
        enqueueSnackbar('File blob data error.', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const tooltipActionsTitle = useMemo(() => {
    if (dmState.isDirty) {
      return 'You have unsaved changes';
    }
    if (selectedPages.length > 0) {
      return 'Select at least one page';
    }
    return '';
  }, [dmState.isDirty, selectedPages]);

  const downloadAttachment = (e, attachment) => {
    e.preventDefault();
    const fileName = attachment?.name.length > 0 ? attachment.name : null;
    fetch(attachment.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        triggerFileDownload(blob, fileName);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('There was a problem downloading the file.', {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 1 }}>
          <Tooltip title="Close and discard changes." placement="top">
            <IconButton sx={{ p: 0, mb: 1 }} onClick={handleClose} size="large">
              <Icon icon={closeOutline} width={28} height={28} />
            </IconButton>
          </Tooltip>
        </Box>
        {selectCertifs}
        {dmState.certificationId && (
          <>
            <Tooltip title={`${tooltipActionsTitle}`} arrow>
              <Box sx={{ display: 'inline-block' }}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{ mr: 1 }}
                  disabled={
                    !dmState.ready ||
                    dmState.isDirty ||
                    !selectedPages.length > 0
                  }
                  loading={Boolean(loading && loading === MODES.toFileManager)}
                  onClick={() => handleActionMode(MODES.toFileManager)}
                >
                  Add to file manager
                </LoadingButton>
              </Box>
            </Tooltip>
            <Tooltip title={`${tooltipActionsTitle}`} arrow>
              <Box sx={{ display: 'inline-block' }}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  disabled={
                    !dmState.ready ||
                    dmState.isDirty ||
                    !selectedPages.length > 0
                  }
                  loading={Boolean(
                    loading && loading === MODES.toQuestionAnswer
                  )}
                  onClick={() => handleActionMode(MODES.toQuestionAnswer)}
                >
                  Add to question answer
                </LoadingButton>
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Box
          component="p"
          sx={{ fontWeight: 'medium', lineHeight: 1, fontSize: 14 }}
        >
          Attachments
        </Box>
        <Box
          component="ul"
          sx={{
            listStyle: 'none',
            mt: 1,
            ml: 1,
            pl: 1,
            borderLeft: '1px dashed rgba(33, 43, 54, 0.4)',
          }}
        >
          {dmState.attachments?.length > 0 &&
            dmState.attachments.map((attachment) => (
              <Box component="li" key={attachment.id}>
                <Stack direction="row" spacing={1} alignItems="center" mb={0}>
                  <Typography
                    variant="body2"
                    color={attachment?.error ? 'error' : 'text.secondary'}
                  >
                    {attachment?.error ? (
                      attachment.name
                    ) : (
                      <b>{attachment.name}</b>
                    )}
                  </Typography>

                  {!attachment?.error && (
                    <DMEditAttachmentPopOver
                      title="Edit attachment name and date"
                      attachment={attachment}
                    />
                  )}

                  {attachment?.url && (
                    <Tooltip title="Download Attachment" arrow>
                      <a
                        href="#"
                        onClick={(e) => downloadAttachment(e, attachment)}
                      >
                        <Typography variant="body2" color="text.secondary">
                          <FileDownloadOutlinedIcon />
                        </Typography>
                      </a>
                    </Tooltip>
                  )}
                </Stack>
                {attachment?.file_date && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="p"
                    mt="-5px"
                  >
                    {attachment?.file_date}
                  </Typography>
                )}

                {attachment?.error && (
                  <TreeTraceback
                    traceback={attachment?.traceback}
                    fileUrl={attachment?.url}
                  />
                )}
              </Box>
            ))}
        </Box>
      </Box>

      {!readOnly && (
        <Box sx={{ mt: 'auto', display: 'flex', alignSelf: 'end' }}>
          <Button
            size="small"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <LoadingButton
            size="small"
            variant="contained"
            disabled={!dmState.ready || !dmState.isDirty}
            loading={saving}
            onClick={() => handleSave()}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

// -----------------------------------------------------------------------

CDMMainMode.propTypes = {
  pdfInstance: PropTypes.object,
  dmState: PropTypes.object,
  selectedPages: PropTypes.array,
  updateDmState: PropTypes.func,
  handleClose: PropTypes.func,
};

CDMMainMode.defaultProps = {
  pdfInstance: null,
  dmState: {},
  selectedPages: [],
  updateDmState: () => null,
  handleClose: () => null,
};

export default React.memo(CDMMainMode);
