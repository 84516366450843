import { format, formatDistanceToNow, isWithinInterval } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  const _date = new Date(date);
  return !isNaN(_date.getTime()) ? format(_date, 'MM/dd/yyyy') : date;
}

export function fDateTime(date, dateFormat) {
  return format(new Date(date), dateFormat || 'MM/dd/yyyy h:mm a');
}

export function fDatePost(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'MM/dd/yyyy p');
}

export function fTime(date) {
  return format(new Date(date), 'h:mm a');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fRawYearsToNow(date) {
  const dob = new Date(date);
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);
  const realyears = Math.abs(age_dt.getUTCFullYear() - 1970);
  return realyears;
}

export function fDateUTC(date) {
  if (date) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }
}

export function fDisplayDate(date) {
  if (date) {
    const [year, month, day] = format(new Date(date), 'yyyy-MM-dd').split('-');
    return `${month}/${day}/${year}`;
  }
}

export function checkDateFormat(date) {
  const isWithinValidRange = isWithinInterval(date, {
    start: new Date(1900, 0, 1),
    end: new Date(2100, 11, 31),
  });
  const formattedDate = format(date, 'MM/dd/yyyy');
  const isCorrectFormat =
    formattedDate === format(new Date(date), 'MM/dd/yyyy');

  return isCorrectFormat && isWithinValidRange;
}
