import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import {
  FILTER_IDS,
  getFullConversation,
  getSelectedConversationMessages,
  setSelectedFilter,
} from 'src/redux/slices/conversationsV2';
import ConversationDetailItemSkeleton from '../ConversationSkeleton';
import { RootStyle } from '../MailDetails';
import MailDetailsToolbar from '../MailDetailsToolbar';
import {
  EXPAND_CONVERSATION,
  EXPAND_MESSAGES,
  FIELDS_CONVERSATION,
  FIELDS_MESSAGES,
  selectMessage,
} from '../utils';
import SmsDetailList from './SmsDetailList';

const SmsDetail = ({ handleOpenCompose }) => {
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const { conversationKey } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const sidebarLabels = useSelector(
    ({ conversationsV2 }) => conversationsV2?.labels,
    shallowEqual
  );
  const { user } = useAuth();
  const { pathname } = useLocation();
  const isUserDashboard = pathname.startsWith('/workbench/user');
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );

  const isLoading = useSelector(
    ({ conversationsV2 }) => conversationsV2?.isLoading,
    shallowEqual
  );
  const update = useSelector(
    ({ conversationsV2 }) => conversationsV2?.update,
    shallowEqual
  );
  const conversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );

  const handleSelectMessage = useCallback(
    (e, msg) => {
      selectMessage(e, {
        msg,
        conversationId: conversation?.id,
        dispatch,
        enqueueSnackbar,
      });
    },
    [conversation?.id, dispatch, enqueueSnackbar]
  );

  const fetchFullConversation = useCallback(
    (conversationId, showLoader = true) => {
      const params = {
        expand: EXPAND_CONVERSATION,
        fields: FIELDS_CONVERSATION,
      };
      dispatch(
        getFullConversation(
          conversationId,
          params,
          conversation?.isUnread,
          showLoader
        )
      );
    },
    [conversation?.isUnread, dispatch]
  );

  const fetchConversationMessages = useCallback(() => {
    dispatch(
      getSelectedConversationMessages(conversationKey, {
        fields: FIELDS_MESSAGES,
        expand: EXPAND_MESSAGES,
      })
    );
  }, [conversationKey, dispatch]);

  useEffect(() => {
    if (update && conversationKey) {
      fetchFullConversation(conversationKey);
    }
  }, [conversationKey, fetchFullConversation, update]);

  useEffect(() => {
    if (conversationKey) {
      fetchFullConversation(conversationKey);
    }
  }, [conversationKey, fetchFullConversation]);

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => fetchConversationMessages(), 30000);
    return () => clearInterval(intervalRef.current);
  }, [fetchConversationMessages]);

  // if the sidebar filter is not correct, set the corresponding filter for this conversation type
  useEffect(() => {
    if (
      conversation?.conversation_type !==
      selectedFilter?.filters?.conversation_type
    ) {
      const filterId = FILTER_IDS.sms;
      dispatch(
        setSelectedFilter({
          ...sidebarLabels[filterId],
          filters:
            {
              ...sidebarLabels[filterId].filters,
              ...(isUserDashboard
                ? { as_specialist: true, file_owner: user?.id }
                : {}),
            } || {},
        })
      );
    }
  }, [
    conversation?.conversation_type,
    dispatch,
    isUserDashboard,
    selectedFilter,
    sidebarLabels,
    user?.id,
  ]);

  if (isLoading) {
    return (
      <RootStyle>
        <ConversationDetailItemSkeleton />
      </RootStyle>
    );
  }

  return (
    <RootStyle>
      <MailDetailsToolbar
        selectMessage={handleSelectMessage}
        firstMessage={conversation?.messages[0]}
        conversation={conversation}
        handleOpenCompose={handleOpenCompose}
        isDone={
          conversation?.certification
            ? conversation?.certification.is_done
            : true
        }
      />

      <SmsDetailList conversation={conversation} />
    </RootStyle>
  );
};

export default SmsDetail;
