import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { shallowEqual } from 'react-redux';
import axiosInstance from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import {
  setSelectedCertification,
  setUpdate,
  toggleConversationReadStatus,
} from 'src/redux/slices/conversationsV2';
import ConversationDocumentManagerDialog, {
  initialState,
} from './ConversationDocumentManagerDialog';
import FTSelectCertificationField from './FTSelectCertificationField';

export default function FTReleteCertification({
  open,
  setOpen,
  property,
  allAttachments,
  isReassign,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const conversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const selectedCertification = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedCertification,
    shallowEqual
  );
  const [isSending, setIsSending] = useState(false);
  const [dmState, setDmState] = useState(initialState);
  const { update } = useSelector((state) => state.conversationsV2);
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    setOpen(false);
    dispatch(setSelectedCertification(null));
  };

  const onRelateCertification = async (relateQuestionOrForm) => {
    try {
      setIsSending(true);
      const dataToSend = {
        certification: selectedCertification.id,
      };
      const response = await axiosInstance.patch(
        `communication/new_conversation/conversation/${conversation.id}/`,
        dataToSend
      ); //eslint-disable-line
      if (response.status === 200) {
        dispatch(setUpdate(!update));
        enqueueSnackbar('Fax was related to a Certification successfully.', {
          variant: 'success',
        });
        if (relateQuestionOrForm) {
          markFax();
        }
        onClose();
        setIsSending(false);
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        'There was an error relating the fax to the Certification.',
        { variant: 'error' }
      );
      setIsSending(false);
    }
  };

  const markFax = () => {
    dispatch(
      toggleConversationReadStatus({
        conversationId: conversation?.id,
        readValue: !conversation?.isUnread,
      })
    );
  };

  const updateDmState = useCallback((obj = {}) => {
    setDmState((st) => ({ ...st, ...obj }));
  }, []);

  const memberOptions = useMemo(() => {
    const options = [];
    const members = selectedCertification?.household_members || [];
    members.forEach((member) => {
      if (
        member.member_type.name.toLowerCase() !==
        'non-household member emergency contact'
      ) {
        options.push(member);
      }
    });
    return options;
  }, [selectedCertification]);

  const handleOpen = () => {
    updateDmState({
      visible: true,
      conversationId: conversation.id,
      certificationId: selectedCertification.id,
      certificationConfig: selectedCertification.certificationConfig,
      initialFile: conversation?.pdf_file_url,
      initialFileName: 'conversation_document',
      attachments: allAttachments || [],
      memberOptions: memberOptions,
      subject: conversation?.subject,
    });
  };

  useEffect(() => {
    if (open && !selectedCertification?.id) {
      dispatch(
        setSelectedCertification(
          conversation?.certification?.id
            ? {
                id: conversation.certification.id,
                display_name: `${conversation.certification?.household?.name} | ${conversation.certification?.display_name}`,
                household_members:
                  conversation.certification?.household?.household_members,
                certificationConfig:
                  conversation.certification?.certification_config,
              }
            : null
        )
      );
    }
  }, [open, conversation, dispatch, selectedCertification]);

  useEffect(() => {
    if (open && dmState.memberOptions !== memberOptions) {
      updateDmState({ ...dmState, memberOptions: memberOptions });
    }
  }, [open, memberOptions, dmState, updateDmState]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {isReassign ? 'Reassign' : 'Relate'} Certification &nbsp;{' '}
        {isLoading && <CircularProgress size={15} />}
      </DialogTitle>
      <DialogContent>
        <FTSelectCertificationField
          property={property}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={!selectedCertification?.id || isSending}
          onClick={() => {
            void onRelateCertification();
          }}
          color="info"
        >
          Relate Certification
        </Button>

        <Button
          variant="contained"
          disabled={!selectedCertification?.id || isSending}
          onClick={() => {
            handleOpen();
          }}
          color="primary"
        >
          Assign Fax to Question or Form
        </Button>

        {!!allAttachments.length && (
          <ConversationDocumentManagerDialog
            dmState={dmState}
            setDmState={setDmState}
            updateDmState={updateDmState}
            attachments={allAttachments || []}
            shouldSelectCertification={{
              property,
              onClose: () => onClose(),
              onSubmit: () => {
                if (!conversation.certification) {
                  onRelateCertification(true);
                }
              },
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
