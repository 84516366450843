import PropTypes from 'prop-types';
import SmsPdfAttachments from './SmsPdfAttachments';
import useAuth from 'src/hooks/useAuth';
import { formatDistanceToNowStrict } from 'date-fns';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import Loader from 'react-loaders';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { isLocalhost } from 'src/utils/isLocalHost';
import { SMS_CONVERSATION_TYPE } from '../utils';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

SmsDetailItem.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
};

export default function SmsDetailItem({ message, conversation }) {
  const { user } = useAuth();

  const messageDelivered = message?.sent;
  const messageFailed = message?.sent_error;
  const [messageStatus, setMessageStatus] = useState(message?.recent_status);
  const [queryCount, setQueryCount] = useState(1);
  const checkInterval = 5000; // in ms

  let sender = message?.sender_user;
  if (conversation.conversation_type === SMS_CONVERSATION_TYPE) {
    sender = message?.sender_user
      ? message.sender_user
      : conversation.hhm_mobile_phone.household_member;
  }
  const senderDetails =
    message?.sender_user?.id === user.id
      ? { type: 'me' }
      : { avatar: '', name: sender.first_name };

  const isMe = senderDetails.type === 'me';
  const username = `${sender.first_name} ${sender.last_name}`;

  const sentOrFailed = messageDelivered || messageFailed;
  const messageSentOrQueued = ['sent', 'queued'].includes(messageStatus);
  const messageNotDelivered = ['undelivered', 'failed'].includes(messageStatus);
  const finalStatusState = ['delivered', 'undelivered', 'failed'].includes(
    messageStatus
  );
  const showLoader = !isLocalhost && isMe && !messageStatus && !sentOrFailed;

  const getMessageState = async () => {
    const { data: messageState } = await axios.get(
      `communication/new_conversation/message/${message?.id}/?fields=recent_status,sent,sent_error`
    );
    setMessageStatus(messageState.recent_status);
  };

  useEffect(() => {
    if (!isLocalhost && isMe && queryCount && !sentOrFailed) {
      if (!finalStatusState) {
        getMessageState();
      } else {
        setQueryCount(0);
        return;
      }

      const advanceCount = setTimeout(
        () => setQueryCount((count) => count + 1),
        checkInterval
      );
      return () => clearTimeout(advanceCount);
    }
  }, [queryCount]);

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {senderDetails.type !== 'me' && (
          <Avatar
            alt={username}
            src={senderDetails.avatar}
            sx={{ width: 32, height: 32 }}
          />
        )}

        <Box
          sx={{
            ml: 2,
            ...(isMe && {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'right',
              alignItems: 'end',
            }),
          }}
        >
          <InfoStyle
            noWrap
            variant="caption"
            sx={{ ...(isMe && { justifyContent: 'flex-end' }) }}
          >
            {!isMe && `${username},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message.created), {
              addSuffix: true,
            })}
          </InfoStyle>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ContentStyle
              sx={{
                ...(isMe && {
                  color: 'grey.800',
                  backgroundColor: 'primary.lighter',
                  ml: -1,
                }),
              }}
            >
              <Typography sx={{ wordBreak: 'break-word' }} variant="body2">
                {message.content}
              </Typography>
            </ContentStyle>
            {messageNotDelivered && (
              <ErrorIcon color={'error'} sx={{ ml: 1 }} />
            )}
          </Box>
          <Box sx={{ mb: messageStatus ? '-22px' : 0 }}>
            {showLoader && (
              <Box sx={{ mr: -3 }}>
                <Loader type="ball-pulse" active color="grey" />
              </Box>
            )}
            {messageSentOrQueued && (
              <Tooltip title={'Sent'} arrow placement={'bottom'}>
                <CheckIcon fontSize={'small'} color={'primary'} />
              </Tooltip>
            )}
            {messageStatus === 'delivered' && (
              <Tooltip title={'Delivered'} arrow placement={'bottom'}>
                <DoneAllIcon fontSize={'small'} color={'primary'} />
              </Tooltip>
            )}
            {messageNotDelivered && (
              <Typography color={'error'} sx={{ mt: '3px', fontSize: '12px' }}>
                Not Delivered
              </Typography>
            )}
          </Box>

          <Box my={'5px'} />

          {message?.files?.length > 0 && (
            <SmsPdfAttachments
              conversationId={conversation?.id}
              certificationId={conversation?.certification?.id}
              certificationConfig={
                conversation?.certification?.certification_config
              }
              files={message.files || []}
              members={
                conversation?.certification?.household?.household_members || []
              }
              subject={conversation?.subject}
            />
          )}
        </Box>
      </Box>
    </RootStyle>
  );
}
