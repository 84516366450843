import axios from '../utils/axios';
import { AxiosResponse } from 'axios';

export type TemplatesEnginePayloadBody = {
  name: string;
  type?: {
    id: number;
    name: string;
    usage: string;
  };
  category?: {
    id: number;
    name: string;
  } | null;
  property?: number | null;
  description?: string | null;
  text?: string | null;
  sms_template?: string | null;
};

export type TemplateRenderBody = {
  property_id?: number;
  template_type?: string;
  template_id?: number;
  user_id?: number;
  conversation_id?: number;
  message_type?: number;
  answers?: any[];
  files?: any[];
  pages?: any[];
  certification_id?: number;
  days?: number | null;
  survey_id?: number | string;
  bulk_communication_final_template?: string;
};

export default class TemplatesEngineService {
  public static getCommunicationTemplates = async (
    queryParams: string
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`templates_engine/template/?${queryParams}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public static createCommunicationTemplate = async (
    body: TemplatesEnginePayloadBody
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`templates_engine/template/`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public static updateCommunicationTemplate = async (
    templateId: number,
    body: TemplatesEnginePayloadBody
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`templates_engine/template/${templateId}/`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public static deleteCommunicationTemplate = async (
    templateId: number
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`templates_engine/template/${templateId}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  public static renderTemplate = async (
    body: TemplateRenderBody
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`templates_engine/render_template/`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public static renderBulkCommunicationTemplate = async (
    body: TemplateRenderBody
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`templates_engine/render_bulk_communication_template/`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
