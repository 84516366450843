import { useEffect } from 'react';
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { Button, FormHelperText, Grid, Stack, TextField } from '@mui/material';
import axiosInstance from 'src/utils/axios';
import GeneralDialog from '../../QuestionMapping/Dialog';
import { useSnackbar } from 'notistack';
import { formatPhoneNumber } from 'src/utils/formatPhoneNumber';
import { useDispatch, useSelector } from 'src/redux/store';
import { getContact } from 'src/redux/slices/ProntoHousingAdministration/Contacts';

export default function ContactModal({
  contact_id = null,
  clnContact,
  reload,
  openModalDialog,
}) {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.contact);
  const { certification } = useSelector((state) => state.certification);
  const { selectedProperty } = useSelector((state) => state.property);
  useEffect(() => {
    if (contact_id) {
      dispatch(
        getContact(contact_id, {
          fields: [
            'id',
            'first_name',
            'last_name',
            'email',
            'phone_number',
            'company.id',
            'company.name',
            'portfolio.id',
            'portfolio.name',
            'property.id',
            'property.name',
            'household.id',
            'household.name',
            'mobile_phone.id',
            'mobile_phone.phone_number',
            'mobile_phones',
            'email_addresses',
            'fax_number',
          ],
          expand: [
            'portfolio',
            'company',
            'property',
            'household',
            'mobile_phones',
            'email_addresses',
            'fax_number',
          ],
        })
      );
    }
  }, [dispatch, contact_id]);

  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        if (contact_id) {
          clnContact();
        }
        openModalDialog();
      }
    };
    window.addEventListener('keyup', close);
    return () => window.removeEventListener('keyup', close);
  }, []); // eslint-disable-line

  const ContactSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    mobile_phones: Yup.array().of(
      Yup.object().shape({
        phone_number: Yup.string()
          .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            'Phone number must be in the format: (123) 456-7890'
          )
          .required('Required'),
      })
    ),
    email_addresses: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Required'),
      })
    ),
    fax_number: Yup.string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        'Fax number must be in the format: (123) 456-7890'
      )
      .nullable(),
  });

  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: contact_id ? contact?.first_name || '' : '',
      last_name: contact_id ? contact?.last_name || '' : '',
      email_addresses: contact_id ? contact?.email_addresses || [] : [],
      mobile_phones: contact_id
        ? contact?.mobile_phones?.map((phone) => {
            return {
              ...phone,
              phone_number: formatPhoneNumber(phone.phone_number.substring(2)),
            };
          })
        : [],
      fax_number: contact_id
        ? formatPhoneNumber(contact?.fax_number?.fax_number?.substring(2)) || ''
        : '',
      mobile_phone_id: contact_id ? contact?.mobile_phone?.id || null : null,
      household: certification?.household?.id
        ? certification?.household?.id || null
        : null,
    },
    validationSchema: ContactSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (
          !values?.mobile_phones?.length &&
          !values?.email_addresses?.length &&
          !(values?.fax_number || '').trim()
        ) {
          const errors = {
            error_communication:
              'At least one communication field is required.',
          };
          setErrors(errors);
          return;
        }
        const contactData = {
          id: contact_id || null,
          first_name: values.first_name,
          last_name: values.last_name,
          household: certification?.household?.id
            ? certification?.household?.id || null
            : null,
          property: selectedProperty.id,
          mobile_phones: values.mobile_phones,
          email_addresses: values.email_addresses,
          fax_number: values.fax_number,
        };

        await axiosInstance.post(
          'contacts/create_or_update_contact/',
          contactData
        );
        clnContact();

        resetForm();
        enqueueSnackbar(
          !contact_id
            ? 'Contact created successfully.'
            : 'Contact updated successfully.',
          { variant: 'success' }
        );
        openModalDialog();
        reload();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const inputsContent = (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps('first_name')}
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps('last_name')}
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
              </Stack>
              <FieldArray name="email_addresses">
                {(arrayHelpers) => {
                  return (
                    <>
                      {values?.email_addresses?.map((email, index) => (
                        <Stack
                          key={`email_addresses.${index}.email`}
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            key={index}
                            fullWidth
                            id={`email_addresses.${index}.email`}
                            name={`email_addresses.${index}.email`}
                            label={`Email ${index + 1}`}
                            type="email"
                            value={values.email_addresses[index].email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              touched?.email_addresses &&
                                touched?.email_addresses[index] &&
                                errors?.email_addresses &&
                                errors?.email_addresses[index]
                            )}
                            helperText={
                              touched?.email_addresses &&
                              touched?.email_addresses[index] &&
                              errors?.email_addresses &&
                              errors?.email_addresses[index]?.email
                            }
                            variant="outlined"
                          />
                        </Stack>
                      ))}
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.push({ email: '' })}
                        variant="contained"
                        color="primary"
                      >
                        Add Email
                      </Button>
                    </>
                  );
                }}
              </FieldArray>

              <FieldArray name="mobile_phones">
                {(arrayHelpers) => {
                  return (
                    <>
                      {values?.mobile_phones?.map((phone, index) => (
                        <Stack
                          key={`mobile_phones.${index}.phone_number`}
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            key={index}
                            fullWidth
                            id={`mobile_phones.${index}.phone_number`}
                            name={`mobile_phones.${index}.phone_number`}
                            label={`Phone ${index + 1}`}
                            type="phone_number"
                            value={values.mobile_phones[index].phone_number}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              touched?.mobile_phones &&
                                touched?.mobile_phones[index] &&
                                errors?.mobile_phones &&
                                errors?.mobile_phones[index]
                            )}
                            helperText={
                              touched?.mobile_phones &&
                              touched?.mobile_phones[index] &&
                              errors?.mobile_phones &&
                              errors?.mobile_phones[index]?.phone_number
                            }
                            variant="outlined"
                            onChange={(evt) => {
                              const newValue = formatPhoneNumber(
                                evt.target.value,
                                evt.target.value.slice(0, -1)
                              );
                              setFieldValue(
                                `mobile_phones.${index}.phone_number`,
                                newValue
                              );
                            }}
                          />
                        </Stack>
                      ))}
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.push({ phone_number: '' })}
                        variant="contained"
                        color="primary"
                      >
                        Add Phone Number
                      </Button>
                    </>
                  );
                }}
              </FieldArray>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TextField
                  fullWidth
                  value={values?.fax_number}
                  label="Fax Number"
                  {...getFieldProps('fax_number')}
                  error={Boolean(touched.fax_number && errors.fax_number)}
                  helperText={touched.fax_number && errors.fax_number}
                  onChange={(evt) => {
                    const newValue = formatPhoneNumber(
                      evt.target.value,
                      evt.target.value.slice(0, -1)
                    );
                    setFieldValue('fax_number', `${newValue}`);
                  }}
                />
              </Stack>
              <FormHelperText error>
                {errors.error_communication}
              </FormHelperText>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );

  return (
    <GeneralDialog
      loading={isSubmitting}
      visible={true}
      title={!contact_id ? 'Create Contact' : 'Edit Contact'}
      onCancel={() => {
        if (contact_id) {
          clnContact();
        }
        openModalDialog();
      }}
      onAccept={handleSubmit}
    >
      {inputsContent}
    </GeneralDialog>
  );
}
