import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
// material
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import axiosInstance from 'src/utils/axios';
import { objToFormData, STATUS_CODES } from '../DocumenManagerV2/utils';
// components
import RecordQuestionnaireTree from '../DocumenManagerV2/RecordQuestionnaireTree';
import useAuth from 'src/hooks/useAuth';
import { datadogAddAction } from 'src/pages/tenant/QuestionnaireV4/utils';
import QuestionnaireService from 'src/services/Questionnaire.service';
import { toFormData } from 'src/utils/toFormData';

// -----------------------------------------------------------------------

const CDMQuestionAnswerMode = ({
  dmState,
  resetActionMode,
  selectCertification,
}) => {
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [treeSelection, setTreeSelection] = useState({
    id: null,
    type: null,
    recordId: null,
  });
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(0);

  const postSuccess = async () => {
    enqueueSnackbar('Answer created successfully.', { variant: 'success' });
    try {
      const { first_name, last_name } =
        dmState?.memberOptions?.find(
          (member) => member.id === selectedMember
        ) || {};
      const logBody = {
        user: user.id,
        certifications: dmState?.certificationId
          ? [dmState.certificationId]
          : [],
        log_name: 'ASSIGN_ATTACHMENT',
        subject: dmState.subject,
        question: treeSelection.questionName,
        household_member: `${first_name || ''} ${last_name || ''}`,
      };

      if (logBody.certifications.length) {
        await axiosInstance.post(`certification/log/`, logBody);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error rebuilding survey record.', { variant: 'error' });
    } finally {
      setSaving(false);
      resetActionMode();
    }
  };

  const postSurveyFile = async (surveyAnswerId) => {
    try {
      const surveyFileBody = objToFormData({
        survey_answer: surveyAnswerId,
        pdf_file_blob: dmState.actionFile,
        status: STATUS_CODES.answered,
      });
      await axiosInstance.post(`questionnaire/survey_file/`, surveyFileBody);
      await postSuccess();
    } catch (error) {
      console.error(error);
      setSaving(false);
      if (error.status === 413) {
        setShowError(true);
        setSizeLimit(error.limit);
      }
      enqueueSnackbar('Error adding to the question answer.', {
        variant: 'error',
      });
    }
  };

  const postAnswer = async (answerGroupId, surveyRecordId) => {
    try {
      const recordWithAnswers = await axiosInstance.get(
        `questionnaire/survey_record/${surveyRecordId}/?expand=answers&fields=answers`
      );

      const selectedAnswer = recordWithAnswers.data.answers.length
        ? recordWithAnswers?.data?.answers.find(
            (ans) => ans.answer_group === answerGroupId
          )
        : null;

      if (recordWithAnswers?.status === 200 && selectedAnswer) {
        await postSurveyFile(selectedAnswer.id);
      } else {
        const answerBody = {
          answer_group: answerGroupId,
          survey_record: surveyRecordId,
          survey_answer_id: selectedAnswer?.id,
          files: [
            {
              file_blob: dmState.actionFile,
            },
          ],
        };

        const encodedFormData = toFormData(answerBody);
        await QuestionnaireService.writeSurveyAnswer(encodedFormData);
        await postSuccess();
      }
    } catch (error) {
      console.error(error);
      setSaving(false);
      enqueueSnackbar('Error creating survey answer.', { variant: 'error' });
    }
  };

  // TODO: backend answerGroup update
  const postAnswerGroup = async (questionGroupId, surveyRecordId) => {
    try {
      const answerGroupBody = objToFormData({
        question_group: questionGroupId,
        survey: selectedSurvey,
      });
      const answerGroupResponse = await axiosInstance.post(
        `questionnaire/answer_group/`,
        answerGroupBody
      );
      datadogAddAction(
        {
          answerGroupID: answerGroupResponse.data.id,
        },
        `Survey Answer Created From ConversationDeliveryManage CDMQuestionAnswerMode.jsx.`
      );

      if (answerGroupResponse.status === 201) {
        await postAnswer(answerGroupResponse.data.id, surveyRecordId);
      }
    } catch (error) {
      console.error(error);
      setSaving(false);
      enqueueSnackbar('Error creating answer group.', { variant: 'error' });
    }
  };

  const handleTreeSelection = useCallback((value) => {
    setTreeSelection(value);
  }, []);

  const getSurvey = useCallback(
    async (memberID) => {
      const { data: surveyData } = await axiosInstance.get(
        `questionnaire/survey/?household_member=${memberID}&certification=${dmState.certificationId}`
      );
      setSelectedSurvey(surveyData[0].id);
    },
    [dmState.certificationId]
  );

  useEffect(() => {
    if (selectedMember) {
      void getSurvey(selectedMember);
    }
  }, [getSurvey, selectedMember]);

  const handleSave = async () => {
    try {
      setSaving(true);

      switch (treeSelection.type) {
        case 'questionGroup':
          await postAnswerGroup(treeSelection.id, treeSelection.recordId);
          return;

        case 'answerGroup':
          await postAnswer(treeSelection.id, treeSelection.recordId);
          return;

        default:
          enqueueSnackbar('Type error.', { variant: 'error' });
          setSaving(false);
          return;
      }
    } catch (error) {
      console.error(error);
      setSaving(false);
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1">Select Household Member:</Typography>
        <TextField
          select
          fullWidth
          size="small"
          value={selectedMember}
          SelectProps={{ native: true }}
          helperText={
            !dmState.memberOptions?.length > 0 &&
            'No household members available.'
          }
          onChange={(event) => {
            setSelectedMember(
              event.target.value ? Number(event.target.value) : null
            );
          }}
        >
          <option value="" />
          {dmState.memberOptions.map((option) => (
            <option key={option?.id} value={option?.id}>
              {`${option.first_name} ${option.last_name}`}
            </option>
          ))}
        </TextField>
      </Box>

      <Box sx={{ mb: 3, height: 'calc(100% - 210px)', overflow: 'auto' }}>
        <RecordQuestionnaireTree
          hhmId={selectedMember}
          disableSelection={saving}
          onSelect={handleTreeSelection}
        />
      </Box>
      {showError && (
        <Box sx={{ mb: 3 }}>
          <Alert
            severity="error"
            sx={{ mb: 3 }}
            onClose={() => setShowError(false)}
          >
            <AlertTitle>Error</AlertTitle>
            {`The file you are attempting to move exceeds the ${sizeLimit}MB limit. Please
            download the file from the attachment list below and resize it
            before uploading to the Questionnaire or File Manager.`}
          </Alert>
        </Box>
      )}

      <Box sx={{ mt: 'auto' }}>
        <Button
          size="small"
          color="inherit"
          sx={{ mr: 2 }}
          onClick={() => resetActionMode()}
        >
          Cancel
        </Button>
        <LoadingButton
          size="small"
          variant="contained"
          disabled={!treeSelection.id || !treeSelection.recordId}
          loading={saving}
          onClick={() => {
            handleSave();
            if (selectCertification) {
              selectCertification();
            }
          }}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

// -----------------------------------------------------------------------

CDMQuestionAnswerMode.propTypes = {
  dmState: PropTypes.object,
  resetActionMode: PropTypes.func,
};

CDMQuestionAnswerMode.defaultProps = {
  dmState: {},
  resetActionMode: () => null,
};

export default React.memo(CDMQuestionAnswerMode);
