import React, { useCallback, useEffect } from 'react';
// routes
import Router from './routes';
// hooks
import useAuth from 'src/hooks/useAuth';
// components
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMPLIANCE_PATHS } from './routes/paths';
import axiosInstance from 'src/utils/axios';
import AchievementModal from './components/AchievementModal/AchievementModal';
import './App.css';
import './styles/Main.scss';
import process from 'process';

const SETUP_PATHNAME = ['/', '/workbench', '/workbench/'];
const TAWK_PROPERTY = process?.env?.REACT_APP_TAWK_PROPERTY;
const TAWK_WIDGET = process?.env?.REACT_APP_TAWK_WIDGET;

// build commit
// ----------------------------------------------------------------------
export default function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isInitialized, isAuthenticated, user } = useAuth();

  const checkSetupProperties = useCallback(() => {
    axiosInstance
      .get(`property_portfolio/property/?fields=setup_completed`)
      .then((response) => {
        if (response.status === 200) {
          const hasPropertiesToSetup = response.data.some(
            (property) => property.setup_completed === false
          );
          if (hasPropertiesToSetup) {
            navigate(COMPLIANCE_PATHS.property.setupList);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [navigate]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isInitialized &&
      user.isCompliance &&
      (SETUP_PATHNAME.includes(pathname) || pathname.includes('auth/'))
    ) {
      checkSetupProperties();
    }
  }, [isAuthenticated, isInitialized, user]); // eslint-disable-line

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
  }, []);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop />
            <GoogleAnalytics />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
        <AchievementModal />
        {TAWK_PROPERTY && TAWK_WIDGET && isAuthenticated && user?.showTawk && (
          <TawkMessengerReact
            propertyId={TAWK_PROPERTY}
            widgetId={TAWK_WIDGET}
          />
        )}
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
