import axios from 'src/utils/axios';
import parseQueryParams from '../utils/query';
import BaseEmailAndPhoneService from './BaseEmailAndPhoneService.service';

// TODO Remove these and refactor this service as well
export const REQUEST_STATUS = {
  WAITING: 'WAITING',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export default class UserService extends BaseEmailAndPhoneService {
  constructor() {
    super('email_login/email_address', 'phones');
  }

  fetchUserHouseholdCertifications = async (userID) => {
    let response = {};
    const params = {
      expand: [
        'household_members.household.property',
        'household_members.member_type',
        'household_members.household.certifications',
        'household_members.household.certifications.applicant_pool',
        'household_members.household.certifications.property',
        'household_members.household.certifications.household',
        'household_members.household.certifications.household.household_members.member_type',
        'household_members.household.certifications.form_signatures_request',
        'household_members.household.certifications.form_signatures_request.needed_signatures',
        'household_members.household.certifications.form_signatures_request.needed_signatures.household_member',
        'household_members.household.certifications.unit',
        'household_members.household.certifications.unit.building',
        'household_members.household.certifications.property.buildings',
        'household_member.member_type',
        'household_member.preferred_language',
        'household_members.preferred_language',
        'badge',
      ],
      field: [
        'household_members.household.certifications',
        'household_members.household.certifications.property',
        'household_members.household.certifications.property.buildings',
      ],
    };
    try {
      response = await axios.get(
        `user_profile/user/${userID}/?${parseQueryParams(params)}`
      );
      response.requestStatus = REQUEST_STATUS.PASSED;
    } catch (error) {
      response.requestStatus = REQUEST_STATUS.FAILED;
      response.error = error;
    }
    return response;
  };

  sendEmailVerification = async (payload) => {
    let response = {};
    try {
      response = await axios.post(
        `email_login/email_address_verification/send_verification/`,
        payload
      );
      return response;
    } catch (e) {
      console.error(e);
      return response;
    }
  };

  sendPhoneVerificationCode = async (payload) => {
    let response = {};
    try {
      response = await axios.post(
        `phones/mobile_phone_verification/send_verification_code/`,
        payload
      );
      return response;
    } catch (e) {
      console.error(e);
      return response;
    }
  };
}

export const userService = new UserService();
