import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  getContactListByHousehold,
  resetContactList,
} from 'src/redux/slices/ProntoHousingAdministration/Contacts';
import { ROWS_PER_PAGE_OPTIONS_ALL } from 'src/utils/tables';
import ContactListToolbar from './ContactListToolbar';
import ContactListHead from './ContactListHead';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import AccountPopoverHolder from 'src/layouts/dashboard/AccountPopoverHolder';
import EmptyTable from 'src/components/EmptyTable';
import ContactModal from '../modals/ContactModal';
import usePaginationLS, { getLsValue } from 'src/hooks/usePaginationLS';
import { formatPhoneNumber } from 'src/utils/formatPhoneNumber';

const LS_ROWS_PER_PAGE_KEY = 'contacts-pagination-value';

export default function ContactList({ conversationMode = false }) {
  const dispatch = useDispatch();
  const { contactList, isLoading, isContactListReady } = useSelector(
    (state) => state.contact
  );
  const { selectedProperty } = useSelector((state) => state.property);
  const { certification } = useSelector((state) => state.certification);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [search, setSearch] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    getLsValue(LS_ROWS_PER_PAGE_KEY)
  );
  const [contactId, setContactId] = useState(null);

  usePaginationLS({
    key: LS_ROWS_PER_PAGE_KEY,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_ALL,
    rowsPerPage,
  });

  const handleSearchUpdate = (event) => {
    if (!isLoading) {
      setSearch(event.target.value);
    }
  };

  /* const getViewPath = (id) => {
   const path = PATH_DASHBOARD.prontoHousingAdministration.contacts.edit;
   return path.replace(CONTACT_ID, id);
   }; */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    _getContacts(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(newSize);
    _getContacts(0, newSize);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setPage(0);
    _getContacts(0, rowsPerPage, newOrder, property);
  };

  const _getContacts = (_page, _rowsPerPage, _order, _orderBy) => {
    const currentPage = _page !== undefined ? _page : page;
    const currentPageSize = _rowsPerPage ? _rowsPerPage : rowsPerPage;
    const currentOrder = _order ? _order : order;
    const currentOrderBy = _orderBy ? _orderBy : orderBy;

    dispatch(
      getContactListByHousehold(
        certification?.household?.id || null,
        selectedProperty?.id,
        {
          fields: [
            'id',
            'first_name',
            'last_name',
            'email',
            'mobile_phone.phone_number',
            'mobile_phone.id',
            'company.id',
            'company.name',
            'portfolio.id',
            'portfolio.name',
            'property.id',
            'property.name',
            'household.id',
            'household.name',
            'fax_number',
            'email_addresses',
            'mobile_phones',
          ],
          expand: [
            'portfolio',
            'company',
            'property',
            'household',
            'mobile_phones',
            'email_addresses',
            'fax_number',
          ],
          // pagination
          page_size: currentPageSize,
          page: currentPage + 1,
          // search
          ...(search ? { search } : {}),
          // ordering
          ...(currentOrderBy
            ? {
                ordering: `${
                  currentOrder === 'desc' ? '-' : ''
                }${currentOrderBy}`,
              }
            : {}),
        }
      )
    );
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      _getContacts();
    }, 500);
    return () => clearTimeout(timeOutId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search !== null) {
      const timeOutId = setTimeout(() => {
        setPage(0);
        // setCurrentSearch(search);
        _getContacts(0);
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetContactList());
    };
  }, []); // eslint-disable-line

  let TABLE_HEAD = [
    {
      id: 'first_name',
      label: 'First Name',
      alignRight: false,
      sort: 'first_name',
    },
    {
      id: 'last_name',
      label: 'Last Name',
      alignRight: false,
      sort: 'last_name',
    },
    {
      id: 'email',
      label: 'Emails',
      alignRight: false,
      sort: 'email_addresses__email',
    },
    {
      id: 'mobile_phone',
      label: 'Mobile Phones',
      alignRight: false,
      sort: 'mobile_phones__phone_number',
    },
    {
      id: 'fax_number',
      label: 'Fax Number',
      alignRight: false,
      sort: 'fax_number__fax_number',
    },
  ];

  if (!conversationMode) {
    const headers = [
      { id: 'company', label: 'Company', alignRight: false, sort: 'company' },
      {
        id: 'portfolio',
        label: 'Portfolio',
        alignRight: false,
        sort: 'portfolio',
      },
      {
        id: 'property',
        label: 'Property',
        alignRight: false,
        sort: 'property',
      },
      {
        id: 'household',
        label: 'Household',
        alignRight: false,
        sort: 'household',
      },
    ];
    TABLE_HEAD = [TABLE_HEAD, ...headers];
  }

  const openModal = () => {
    setShowDialog(!showDialog);
  };

  const reloadTable = () => {
    _getContacts();
  };

  const openModalEdit = (contactId) => {
    setContactId(contactId);
    openModal();
  };

  const cleanContact = () => {
    setContactId(null);
  };

  const filContacts =
    'results' in contactList ? contactList.results : contactList;
  const contactsNotFound = filContacts.length === 0;
  const renderTableContent = (
    <>
      <TableContainer>
        <Table>
          {isContactListReady && !contactsNotFound && (
            <>
              <ContactListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filContacts.map((contact, idx) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      role="checkbox"
                      sx={{ cursor: 'pointer' }}
                      key={`${idx}-${contact.id}`}
                      onClick={() => openModalEdit(contact.id)}
                    >
                      <TableCell align="left">
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                          component="div"
                          variant="body2"
                          noWrap={true}
                        >
                          <Typography variant="body2" noWrap={true}>
                            {contact.first_name || (
                              <Label variant="ghost" color="default">
                                N/A
                              </Label>
                            )}
                          </Typography>

                          {!contact?.household && (
                            <Label
                              sx={{ ml: 1 }}
                              variant="ghost"
                              color="default"
                            >
                              Property
                            </Label>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" noWrap={true}>
                          {contact.last_name || (
                            <Label variant="ghost" color="default">
                              N/A
                            </Label>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" noWrap={true}>
                          {(contact?.email_addresses?.length &&
                            contact.email_addresses.map((email) => (
                              <div key={email.id}>{email.email}</div>
                            ))) || (
                            <Label variant="ghost" color="default">
                              N/A
                            </Label>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" noWrap={true}>
                          {(contact?.mobile_phones?.length &&
                            contact.mobile_phones.map((phone) => (
                              <div key={phone.id}>
                                {formatPhoneNumber(
                                  phone.phone_number.substring(2)
                                )}
                              </div>
                            ))) || (
                            <Label variant="ghost" color="default">
                              N/A
                            </Label>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" noWrap={true}>
                          {formatPhoneNumber(
                            contact?.fax_number?.fax_number.substring(2)
                          ) || (
                            <Label variant="ghost" color="default">
                              N/A
                            </Label>
                          )}
                        </Typography>
                      </TableCell>

                      {!conversationMode && (
                        <>
                          <TableCell align="left">
                            <Typography variant="body2" noWrap={true}>
                              {contact.company?.name || (
                                <Label variant="ghost" color="default">
                                  N/A
                                </Label>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body2" noWrap={true}>
                              {contact.portfolio?.name || (
                                <Label variant="ghost" color="default">
                                  N/A
                                </Label>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body2" noWrap={true}>
                              {contact.property?.name || (
                                <Label variant="ghost" color="default">
                                  N/A
                                </Label>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body2" noWrap={true}>
                              {contact.household?.name || (
                                <Label variant="ghost" color="default">
                                  N/A
                                </Label>
                              )}
                            </Typography>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )}
          {isContactListReady && contactsNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                  <EmptyTable objectName="Contacts" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_ALL}
        component="div"
        count={
          (contactList?.count ? contactList.count : contactList.length) || 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );

  return (
    <>
      {!conversationMode ? (
        <Page title="Contacts | Pronto Housing">
          <HeaderBreadcrumbs heading="Contacts" links={[{ name: '' }]} />
          <AccountPopoverHolder />
          <Card sx={{ p: 3 }}>
            <ContactListToolbar
              value={search}
              onSearch={handleSearchUpdate}
              disabled={isLoading}
              isLoading={isLoading}
            />
            <Scrollbar>{renderTableContent}</Scrollbar>
          </Card>
        </Page>
      ) : (
        <Page title="Contacts | Pronto Housing" sx={{ width: '100%', p: 3 }}>
          <ContactListToolbar
            value={search}
            onSearch={handleSearchUpdate}
            disabled={isLoading}
            isLoading={isLoading}
            openModalDialog={openModal}
          />
          <Scrollbar sx={{ height: 'calc(100% - 96px)' }}>
            {renderTableContent}
          </Scrollbar>
        </Page>
      )}

      {showDialog && (
        <ContactModal
          contact_id={contactId}
          clnContact={() => cleanContact()}
          reload={reloadTable}
          openModalDialog={openModal}
        />
      )}
    </>
  );
}
