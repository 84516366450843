import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userDashboardReducer from './slices/UserDashboard/userDashboard';
import tenantReducer from './slices/Tenant/Tenant';
import mainReducer from './slices/Main/main';
import questionnaireSummaryReducer from './slices/Questionnaire/QuestionnaireSummary';
import questionnaireProgressReducer from './slices/Questionnaire/QuestionnaireProgress';
import propertyReducer from './slices/Compliance/Property';
import notificationReducer from './slices/Compliance/Notifications';
import questionReducer from './slices/ProntoHousingAdministration/Question';
import contactsReducer from './slices/ProntoHousingAdministration/Contacts';
import dynamicRulesReducer from './slices/ProntoHousingAdministration/DynamicRules';
import questionMapping from './slices/ProntoHousingAdministration/QuestionMapping';
import formReducer from './slices/ProntoHousingAdministration/Form';
import certificationReducer from './slices/Compliance/Certification';
import fileManagerReducer from './slices/Compliance/FileManager';
import documentManagerReducer from './slices/Compliance/DocumentManager';
import documentManagerReducerV2 from './slices/Compliance/DocumentManagerV2';
import formManager from './slices/Compliance/FormManager';
import conversationsReducer from './slices/Compliance/Conversations';
import unitReducer from './slices/Compliance/Unit';
import buildingReducer from './slices/Compliance/Building';
import householdReducer from './slices/Compliance/Household';
import conversationsV2 from './slices/conversationsV2';
import propertySetupReducer from './slices/PropertySetup/PropertySetup';
import certificationWorkbenchReducer from './slices/CertificationWorkbench/CertificationWorkbench';
import selectionCriteriaReducer from './slices/BulkCommunications/SelectionCriteriaSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  userDashboard: userDashboardReducer,
  property: propertyReducer,
  question: questionReducer,
  form: formReducer,
  tenant: tenantReducer,
  questionnaireProgress: questionnaireProgressReducer,
  main: mainReducer,
  contact: contactsReducer,
  dynamicRules: dynamicRulesReducer,
  certification: certificationReducer,
  fileManager: fileManagerReducer,
  documentManager: documentManagerReducer,
  documentManagerV2: documentManagerReducerV2,
  formManager: formManager,
  conversations: conversationsReducer,
  conversationsV2: conversationsV2,
  questionMapping: questionMapping,
  unit: unitReducer,
  building: buildingReducer,
  household: householdReducer,
  notification: notificationReducer,
  questionnaireSummary: questionnaireSummaryReducer,
  propertySetup: propertySetupReducer,
  certificationConfigurationWorkbench: certificationWorkbenchReducer,
  selectionCriteria: selectionCriteriaReducer,
});

export { rootPersistConfig, rootReducer };
