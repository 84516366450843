import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
// redux
import { useDispatch } from 'src/redux/store';

import ConversationDetailItemSkeleton from './ConversationSkeleton';
import { shallowEqual, useSelector } from 'react-redux';
import {
  FILTER_IDS,
  getFullConversation,
  getSelectedConversationMessages,
  setSelectedConversation,
  setSelectedFilter,
  setShouldReload,
  updateSelectedConversation,
} from 'src/redux/slices/conversationsV2';
import axios from 'src/utils/axios';
import axiosInstance from 'src/utils/axios';
import {
  EXPAND_CONVERSATION,
  EXPAND_MESSAGES,
  FIELDS_CONVERSATION,
  FIELDS_MESSAGES,
} from './utils';
import FaxDetailsToolbar from './FaxDetailsToolbar';
import FaxDetailsBody from './FaxDetailsBody';
import { fPhone } from 'src/utils/formatNumber';
import ConversationDocumentManager from 'src/components/ConversationManager/ConversationDocumentManager';
import Scrollbar from '../Scrollbar';
import { COMPLIANCE_PATHS } from 'src/routes/paths';
import { useLocation, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

// ----------------------------------------------------------------------

const FaxDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { conversationKey } = useParams();
  const { search, pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const sidebarLabels = useSelector(
    ({ conversationsV2 }) => conversationsV2?.labels,
    shallowEqual
  );
  const { user } = useAuth();
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );

  const conversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const isLoading = useSelector(
    ({ conversationsV2 }) => conversationsV2?.isLoading,
    shallowEqual
  );
  const update = useSelector(
    ({ conversationsV2 }) => conversationsV2?.update,
    shallowEqual
  );

  const [detailData, setDetailData] = useState([]);

  const isUserDashboard = pathname.startsWith('/workbench/user');

  useEffect(() => {
    const setRead = async () => {
      await axiosInstance.patch(
        `communication/new_conversation/conversation/${conversation.id}/`,
        { has_new_message: false }
      );
    };

    if (conversation.isUnread) {
      setRead().catch(console.error);
    }
  }, [conversation]);

  const buildDetailData = useCallback(
    (isInbound) => {
      const { documo_metadata, status } = conversation;

      let data = [];

      if (status === 'success') {
        // Data for Inbound Fax
        if (isInbound && !!documo_metadata) {
          data = [
            {
              title: 'Caller Subscriber ID',
              value: documo_metadata?.account?.accountName,
            },
            {
              title: 'Fax Number',
              value: documo_metadata?.faxNumber
                ? fPhone(documo_metadata?.faxNumber.replace('+1', ''))
                : null,
            },
            { title: 'Caller ID', value: documo_metadata?.faxCallerId },
            { title: 'Number of pages', value: documo_metadata?.pagesCount },
            { title: 'Attempt', value: documo_metadata?.faxAttempt || 1 },
            { title: 'Delivery ID', value: documo_metadata?.deliveryId },
          ];
        }

        // Data for Outbound Fax
        if (!isInbound && !!documo_metadata.content) {
          data = [
            {
              title: 'Caller Subscriber ID',
              value: documo_metadata?.account?.accountName,
            },
            {
              title: 'Fax Number',
              value: documo_metadata?.faxNumber
                ? fPhone(documo_metadata?.faxNumber.replace('+1', ''))
                : null,
            },
            {
              title: 'Number of pages',
              value: documo_metadata?.content?.pagesCount,
            },
            {
              title: 'Delivery ID',
              value: documo_metadata?.content?.deliveryId,
            },
            { title: 'Attempt', value: documo_metadata?.faxAttempt || 1 },
          ];
        }
      }

      if (status === 'failed') {
        data = [
          { title: 'Status', value: 'FAILED' },
          {
            title: 'Number of pages',
            value: documo_metadata?.content?.pagesCount,
          },
          {
            title: 'Error',
            value: documo_metadata?.error_message
              ? documo_metadata?.error_message
              : conversation?.documo_metadata?.content?.resultInfo
              ? conversation?.documo_metadata?.content?.resultInfo
              : 'There was en error sending this fax message. Please contact the administrator.',
          },
        ];
      }

      if (status === 'pending' || !status) {
        data = [{ title: 'Status', value: 'PENDING' }];
        /*     setAlertMessage({type: 'warning', message: 'The fax message is still sending. Please come back in a few seconds.'}) */
      }

      setDetailData(data);
    },
    [conversation]
  );

  const intervalRef = useRef(null);

  const fetchFullConversation = useCallback(
    (showLoader = true) => {
      dispatch(
        getFullConversation(
          conversationKey,
          {
            expand: EXPAND_CONVERSATION,
            fields: FIELDS_CONVERSATION,
          },
          conversation?.isUnread,
          showLoader
        )
      );
    },
    [conversation?.isUnread, conversationKey, dispatch, update] //eslint-disable-line
  );

  const fetchConversationMessages = () => {
    dispatch(
      getSelectedConversationMessages(conversationKey, {
        fields: FIELDS_MESSAGES,
        expand: EXPAND_MESSAGES,
      })
    );
  };

  const allAttachments = useMemo(
    () =>
      conversation?.messages?.length > 0
        ? conversation.messages.reduce(
            (acc, curr) => [...acc, ...curr.files],
            []
          )
        : [],
    [conversation?.messages]
  );

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (!conversation?.id) {
      return;
    }
    intervalRef.current = setInterval(() => {
      if (!conversation?.pdf_file_url) {
        axios
          .get(
            `communication/new_conversation/conversation/${conversation.id}/?fields=pdf_file_url`
          )
          .then((response) => {
            if (response.status === 200 && response.data?.pdf_file_url) {
              dispatch(
                updateSelectedConversation({
                  value: { pdf_file_url: response.data.pdf_file_url },
                  conversationId: conversation.id,
                })
              );
            }
          });
      }
      fetchConversationMessages();
    }, 30000);
    return () => clearInterval(intervalRef.current);
  }, [conversation?.id, conversation?.pdf_file_url]); //eslint-disable-line

  const returnToList = () => {
    if (!isUserDashboard) {
      if (conversation?.certification.id) {
        navigate(
          `${COMPLIANCE_PATHS.root}/${conversation.certification.property.slug}/${conversation.certification.id}${search}`
        );
      } else {
        navigate(
          `${COMPLIANCE_PATHS.root}/${conversation.certification.property.slug}${search}`
        );
      }
    } else {
      navigate(`${COMPLIANCE_PATHS.root}/user/communications/${search}`);
    }
  };

  const retryFax = async () => {
    try {
      const response = await axiosInstance.post(
        `communication/new_conversation/conversation/retry_send_fax/`,
        { conversation: conversation.id }
      );
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar('The fax is being sent.', { variant: 'info' });
        returnToList();
        dispatch(setShouldReload(true));
      }
    } catch (e) {
      enqueueSnackbar('There was an error sending the fax.', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const deleteConversation = async () => {
    try {
      await axios
        .delete(
          `communication/new_conversation/conversation/${conversation?.id}/`
        )
        .then(() => {
          dispatch(
            setSelectedConversation({
              conversation: null,
              conversationHadNewMessages: false,
            })
          );
          returnToList();
          enqueueSnackbar('Fax Deleted', { variant: 'success' });
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchFullConversation();
  }, [update]); //eslint-disable-line

  useEffect(() => {
    if (conversationKey) {
      fetchFullConversation();
    }
  }, [conversationKey]); //eslint-disable-line

  // remove selected conversation when the component is unmounted
  useEffect(() => {
    return () => {
      dispatch(
        setSelectedConversation({
          conversation: null,
          conversationHadNewMessages: false,
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    buildDetailData(!conversation?.messages[0]?.sender_user);
  }, [buildDetailData, conversation?.messages]);

  // if the sidebar filter is not correct, set the corresponding filter for this conversation type
  useEffect(() => {
    if (
      conversation?.conversation_type !==
      selectedFilter?.filters?.conversation_type
    ) {
      const filterId = FILTER_IDS.fax;
      dispatch(
        setSelectedFilter({
          ...sidebarLabels[filterId],
          filters:
            {
              ...sidebarLabels[filterId].filters,
              ...(isUserDashboard
                ? { as_specialist: true, file_owner: user?.id }
                : {}),
            } || {},
        })
      );
    }
  }, [
    conversation?.conversation_type,
    dispatch,
    isUserDashboard,
    selectedFilter,
    sidebarLabels,
    user?.id,
  ]);

  if (!conversation) {
    return null;
  }

  return (
    <RootStyle>
      {isLoading && <ConversationDetailItemSkeleton />}

      {!isLoading && (
        <>
          {/*HEADER*/}
          <FaxDetailsToolbar
            faxMessage={conversation?.messages[0]}
            fax={conversation}
            allAttachments={allAttachments}
            retry={() => retryFax()}
          />
          <Divider />

          <Scrollbar
            sx={{
              flexGrow: 1,
              height: '100%',
              '& .simplebar-content': { height: '100%' },
            }}
          >
            {/*FAX DETAILS*/}
            <FaxDetailsBody
              subject={conversation.subject || 'New Inbound Fax'}
              details={detailData}
              onRefresh={fetchFullConversation}
              onDelete={deleteConversation}
              onRetry={retryFax}
            />

            {/*FAX ATTACHMENTS*/}

            {!!allAttachments.length && (
              <ConversationDocumentManager
                conversationId={conversation?.id}
                certificationId={conversation?.certification?.id}
                certificationConfig={
                  conversation?.certification?.certification_config
                }
                pdfFileUrl={conversation?.pdf_file_url}
                files={conversation?.messages[0]?.files || []}
                attachments={allAttachments || []}
                members={
                  conversation?.certification?.household?.household_members ||
                  []
                }
                subject={conversation?.subject}
              />
            )}
          </Scrollbar>
        </>
      )}
    </RootStyle>
  );
};

export default memo(FaxDetails);
