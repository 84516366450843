import PropTypes from 'prop-types';
// material
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
//
import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/redux/store';
// ----------------------------------------------------------------------

ChatRoomAttachmentDialog.propTypes = {
  members: PropTypes.array,
};

export default function ChatRoomAttachmentDialog({
  certification,
  members,
  attachmentSelected,
  setAttachmentSelected,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { selectedProperty } = useSelector((state) => state.property);

  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [addAs, setAddAs] = useState('standalone');
  const [selectedForm, setSelectedForm] = useState(null);
  const [formOptions, setFormOptions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [answerOptions, setAnswerOptions] = useState(null);
  const [answerGroups, setAnswerGroups] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswerGroup, setSelectedAnswerGroup] = useState(null);
  const [isAppend, setIsAppend] = useState(false);
  const [newMembers, setNewMembers] = useState([]);
  const [documentName, setDocumentName] = useState('');
  const [successfullySent, setSuccessfullySent] = useState(false);

  const handleChange = (event) => {
    setAddAs(event.target.value);
  };

  const handleClose = () => {
    setAttachmentSelected(null);
  };

  const handleSave = async () => {
    if (addAs === 'standalone') {
      await _sendStandaloneDocument(attachmentSelected);
    } else if (addAs === 'answer') {
      await _sendAttachmentAsAnAnswer(
        attachmentSelected,
        selectedRecord,
        selectedAnswer,
        selectedAnswerGroup,
        isAppend
      );
    } else {
      await _sendAttachmentAsFormCopy(attachmentSelected);
    }
  };

  const setRefresh = () => {
    handleRefresh(false);
  };

  const _sendStandaloneDocument = async (attachment) => {
    const members_ = [];
    if (!documentName || documentName === '') {
      enqueueSnackbar('Enter a name for the document.', { variant: 'error' });
      return;
    }

    if (selectedMembers.length > 0) {
      selectedMembers.forEach((member) => {
        members_.push(member.id);
      });
    } else {
      enqueueSnackbar('Select at least one household member.', {
        variant: 'error',
      });
      setSuccessfullySent(false);
      return;
    }

    const dataToSend = {
      attachment: attachment.id,
      document_name: documentName,
      household_members: members_,
    };
    try {
      const response = await axiosInstance.post(
        `certification/document/create_from_attachment/`,
        dataToSend
      );
      if (response.status === 201) {
        enqueueSnackbar('Successfully added to file manager.', {
          variant: 'success',
        });
        setSuccessfullySent(true);
      }
    } catch (error) {
      enqueueSnackbar(
        'There was an error adding the file to the file manager.',
        { variant: 'error' }
      );
      setSuccessfullySent(false);
    }
  };

  const _sendAttachmentAsAnAnswer = async (
    attachment,
    record,
    answer,
    answerGroup,
    append
  ) => {
    if (!record) {
      enqueueSnackbar('You must select a question.', { variant: 'error' });
      setSuccessfullySent(false);
      return;
    }

    const dataToSend = {
      attachment: attachment.id,
      survey_record: record.id,
      survey_answer: answer ? answer.id : null,
      survey_answer_group: answerGroup ? answerGroup.id : null,
      append: append,
    };

    try {
      const response = await axiosInstance.post(
        `questionnaire/survey_answer/relate_attachment/`,
        dataToSend
      );
      if (response.status === 201) {
        enqueueSnackbar('Successfully added answer to a question.', {
          variant: 'success',
        });
        setSuccessfullySent(true);
      }
    } catch (error) {
      enqueueSnackbar('There was an error adding the file to the question.', {
        variant: 'error',
      });
      setSuccessfullySent(false);
    }
  };

  const _sendAttachmentAsFormCopy = async (attachment) => {
    if (!selectedForm) {
      enqueueSnackbar('You must select a form.', { variant: 'error' });
      return;
    }

    let content_type_id = null;
    const response_content_type = await axiosInstance.get(
      'mapping_question/content_types/?model=form'
    );
    if (response_content_type.status === 200) {
      content_type_id = response_content_type.data[0].id;
    } else {
      enqueueSnackbar(
        'There was an error replacing the form with attachment.',
        { variant: 'error' }
      );
      return;
    }

    const dataToSend = {
      attachments_id: [attachment.id],
      destination_type: 'certification_document',
      destination: { certification_file: certification.certification_file },
      form_relation: {
        content_type_id: content_type_id,
        object_id: selectedForm.id,
      },
    };

    try {
      const response = await axiosInstance.post(
        `communication/new_conversation/message_attachment/save_attachments/`,
        dataToSend
      );
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar('Successfully added as a form copy.', {
          variant: 'success',
        });
        setSuccessfullySent(true);
      }
    } catch (error) {
      enqueueSnackbar(
        'There was an error replacing the form with attachment.',
        { variant: 'error' }
      );
      setSuccessfullySent(false);
    }
  };

  const _getQuestions = async (member) => {
    const response = await axiosInstance.get(
      `questionnaire/survey_record/?survey__household_member=${member.id}&expand=survey,qit.question,qit.question_group&question_type=file&fields=id,qit.question.name,qit.question_group,qit.number_of_answers_required,qit.question_group.type`
    );
    if (response.status === 200) {
      setQuestions(response.data);
    }
  };

  const _getFormOptions = async () => {
    const response = await axiosInstance.get(
      `form_library/form_table/?object_id=${selectedProperty.id}&content_type__model=property&expand=form`
    );
    if (response.status === 200) {
      setFormOptions(response.data);
    }
  };

  const _checkAnswer = async (surveyRecord) => {
    const response = await axiosInstance.get(
      `questionnaire/survey_answer/?survey_record=${surveyRecord.id}&fields=id,answer_group,text,file,is_generating,generation_status,exception_info&expand=answer_group,answer_group.question_group`
    );
    if (response.status === 200) {
      if (surveyRecord.qit.question_group.type === 'single') {
        if (
          surveyRecord.qit.number_of_answers_required === 1 &&
          response.data.length === 1
        ) {
          setSelectedAnswer(response.data[0]);
        } else {
          setAnswerOptions(response.data);
        }
      } else {
        const answerGroups_ = {};

        response.data.forEach((answer) => {
          if (!answerGroups_[answer.answer_group.id]) {
            answerGroups_[answer.answer_group.id] = answer.answer_group;
          }
        });

        setAnswerGroups(
          Object.values(answerGroups_).map((ag, ix) => {
            ag.index = ix + 1;
            return ag;
          })
        );
        setAnswers(response.data);
      }
    }
  };

  const _settingMembers = (_members) => {
    const _newMembers = [];
    _members.forEach((member) => {
      if (
        member.member_type.name.toLowerCase() !==
        'non-household member emergency contact'
      ) {
        _newMembers.push(member);
      }
    });
    setNewMembers(_newMembers);
  };

  useEffect(() => {
    _settingMembers(members);
    //eslint-disable-next-line
  }, [attachmentSelected]);

  useEffect(() => {
    if (selectedMember) {
      void _getQuestions(selectedMember);
    }
  }, [selectedMember]);

  useEffect(() => {
    if (selectedRecord?.id) {
      void _checkAnswer(selectedRecord);
    }
  }, [selectedRecord]);

  useEffect(() => {
    if (answers && answers.length > 0 && selectedAnswerGroup) {
      const filteredAnswers = answers.filter(
        (answer) => answer.answer_group.id === selectedAnswerGroup.id
      );

      if (
        selectedRecord.qit.number_of_answers_required === 1 &&
        filteredAnswers.length === 1
      ) {
        setSelectedAnswer(filteredAnswers[0]);
        setAnswerOptions(null);
      } else {
        setAnswerOptions(filteredAnswers);
      }
    }
    //eslint-disable-next-line
  }, [selectedAnswerGroup]);

  useEffect(() => {
    void _getFormOptions();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (successfullySent) {
      setRefresh();
      setAttachmentSelected(null);
    }
  }, [successfullySent]); //eslint-disable-line

  return (
    attachmentSelected && (
      <>
        <Dialog
          open={Boolean(attachmentSelected)}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Add to File Manager.</DialogTitle>
          <DialogContent>
            <DialogContentText>
              How would you like to add this file to the File Manager?
            </DialogContentText>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <RadioGroup row value={addAs} onChange={handleChange}>
                <FormControlLabel
                  value={'standalone'}
                  control={<Radio />}
                  label="As a standalone document"
                />
                <FormControlLabel
                  value={'formcopy'}
                  control={<Radio />}
                  label="As a form copy"
                />
                <FormControlLabel
                  value={'answer'}
                  control={<Radio />}
                  label="As a question answer"
                />
              </RadioGroup>
            </FormControl>
            {addAs === 'answer' && (
              <Grid container>
                <Grid item xs={4}>
                  <Autocomplete
                    value={selectedMember}
                    onChange={(event, newValue) => setSelectedMember(newValue)}
                    options={newMembers || []}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderInput={(params) => (
                      <TextField label="Household Member" {...params} />
                    )}
                    fullWidth
                    freeSolo
                    disableClearable
                    sx={{ pr: 2 }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    disabled={!questions?.length}
                    onChange={(event, newValue) => {
                      setSelectedRecord(newValue);
                    }}
                    options={questions || []}
                    getOptionLabel={(option) => `${option.qit.question.name}`}
                    renderInput={(params) => (
                      <TextField label="Question to relate to" {...params} />
                    )}
                    fullWidth
                    freeSolo
                  />

                  {!questions?.length && (
                    <FormHelperText>
                      {!selectedMember
                        ? 'Select a member first.'
                        : 'No questions available.'}
                    </FormHelperText>
                  )}
                </Grid>

                {answerGroups && answerGroups.length > 0 && (
                  <Autocomplete
                    onChange={(event, newAnswerGroup) => {
                      setSelectedAnswerGroup(newAnswerGroup);
                    }}
                    options={answerGroups}
                    getOptionLabel={(option) =>
                      `${option.question_group.name} ${option.index}`
                    }
                    renderInput={(params) => (
                      <TextField label="Select Answer Group" {...params} />
                    )}
                    fullWidth
                    freeSolo
                    sx={{ mt: 2 }}
                  />
                )}

                {(selectedRecord?.qit.question_group.type === 'single' ||
                  (selectedRecord?.qit.question_group.type === 'multi' &&
                    selectedAnswerGroup?.id)) &&
                answerOptions &&
                answerOptions.length > 0 ? (
                  <>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <DialogContentText>
                        Select an answer to relate the file. Number of answers
                        required:{' '}
                        {selectedRecord.qit.number_of_answers_required}
                      </DialogContentText>
                    </Grid>

                    <Autocomplete
                      onChange={(event, newAnswer) => {
                        setSelectedAnswer(newAnswer);
                      }}
                      options={
                        answerOptions.length <
                        selectedRecord.qit.number_of_answers_required
                          ? [
                              ...answerOptions,
                              {
                                id: 0,
                                text: 'Add New Answer',
                              },
                            ]
                          : answerOptions
                      }
                      getOptionLabel={(option) => `${option.text}`}
                      renderInput={(params) => (
                        <TextField label="Select Answer" {...params} />
                      )}
                      fullWidth
                      freeSolo
                    />
                  </>
                ) : null}

                {selectedAnswer?.file && (
                  <>
                    <Grid item xs={4}>
                      <ToggleButtonGroup
                        color="primary"
                        value={isAppend}
                        exclusive
                        onChange={() => setIsAppend(!isAppend)}
                        sx={{ mt: 2 }}
                      >
                        <ToggleButton value={false} color="error">
                          Replace
                        </ToggleButton>
                        <ToggleButton value={true} color="primary">
                          Append
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {addAs === 'standalone' && (
              <>
                <TextField
                  fullWidth
                  label="Document Name"
                  onChange={(e) => setDocumentName(e.target.value)}
                />
                <Autocomplete
                  multiple
                  value={selectedMembers}
                  onChange={(event, newValue) => setSelectedMembers(newValue)}
                  options={newMembers || []}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  renderInput={(params) => (
                    <TextField label="Household Member" {...params} />
                  )}
                  fullWidth
                  freeSolo
                  disableClearable
                  sx={{ mt: 2 }}
                />
              </>
            )}

            {addAs === 'formcopy' && (
              <>
                <Autocomplete
                  value={selectedForm}
                  onChange={(event, newValue) => setSelectedForm(newValue)}
                  options={formOptions || []}
                  getOptionLabel={(option) => option.form.name}
                  renderInput={(params) => (
                    <TextField label="Select a form" {...params} />
                  )}
                  fullWidth
                  freeSolo
                  disableClearable
                  sx={{ mt: 2 }}
                />
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
}
