import {
  SurveyAnswer,
  SurveyAnswersPayload,
} from 'src/types/Questionnaire/QuestionnaireTypes';
import axios from '../utils/axios';
import { AxiosResponse } from 'axios';

export default class QuestionnaireService {
  public static writeSurveyAnswers = async (
    surveyAnswersBody: SurveyAnswersPayload | FormData
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .post('questionnaire/write_survey_answers/', surveyAnswersBody)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public static writeSurveyAnswer = async (
    surveyAnswersBody: SurveyAnswer | FormData
  ): Promise<AxiosResponse> => {
    let response;
    try {
      response = await axios.post(
        'questionnaire/write_survey_answer/',
        surveyAnswersBody
      );
    } catch (error) {
      console.log(error);
    }
    return response;
  };

  public static deleteAnswerGroup = async (
    answerGroupId: number
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`questionnaire/answer_group/${answerGroupId}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
