import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
// material
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import axiosInstance from 'src/utils/axios';
import { objToFormData } from '../DocumenManagerV2/utils';

// -----------------------------------------------------------------------

const CDMFileManagerMode = ({
  dmState,
  resetActionMode,
  selectCertification,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedProperty } = useSelector((state) => state.property);

  const [selectedForm, setSelectedForm] = useState(null);
  const [formName, setFormName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(0);

  const getFormOptions = useCallback(async () => {
    if (!dmState?.certificationConfig) {
      return;
    }
    const response = await axiosInstance.get(
      `form_library/form_table/?object_id=${selectedProperty.id}&certification_config=${dmState.certificationConfig}&content_type__model=property&expand=form`
    );
    if (response.status === 200) {
      setFormOptions(response.data);
    }
  }, [dmState?.certificationConfig, selectedProperty.id]);

  const handleSave = async () => {
    try {
      setSaving(true);
      const formData = objToFormData({
        file: dmState.actionFile,
        file_name: formName,
        certification_id: dmState.certificationId,
        household_members: selectedMembers.map((member) => member.id),
        form_id: selectedForm?.form?.id || null,
        conversation_subject: dmState.subject,
      });
      const response = await axiosInstance.post(
        `certification/document/create_from_attachment/`,
        formData
      );
      if (response.status === 201) {
        if (selectCertification) {
          selectCertification();
        }
        enqueueSnackbar('Certification document created successfully.', {
          variant: 'success',
        });
        resetActionMode();
      }
    } catch (error) {
      console.error(error);
      if (error.status === 413) {
        setShowError(true);
        setSizeLimit(error.limit);
      }
      enqueueSnackbar('Error adding to the file manager.', {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    getFormOptions();
  }, [getFormOptions]);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          size="small"
          label="Document name"
          onChange={(event) => setFormName(event.target.value)}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Autocomplete
          multiple
          fullWidth
          size="small"
          value={selectedMembers}
          onChange={(event, newValue) => setSelectedMembers(newValue)}
          options={dmState.memberOptions || []}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select household members"
              helperText={
                !dmState.memberOptions?.length > 0 &&
                'No household members available.'
              }
            />
          )}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Autocomplete
          fullWidth
          size="small"
          value={selectedForm}
          onChange={(event, newValue) => setSelectedForm(newValue)}
          options={formOptions || []}
          getOptionLabel={(option) => option.form.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Form (optional)"
              helperText={!formOptions?.length > 0 && 'No forms available.'}
            />
          )}
        />
      </Box>
      {showError && (
        <Box sx={{ mb: 3 }}>
          <Alert
            severity="error"
            sx={{ mb: 3 }}
            onClose={() => setShowError(false)}
          >
            <AlertTitle>Error</AlertTitle>
            {`The file you are attempting to move exceeds the ${sizeLimit}MB limit. Please
            download the file from the attachment list below and resize it
            before uploading to the Questionnaire or File Manager.`}
          </Alert>
        </Box>
      )}
      <Box sx={{ mt: 'auto' }}>
        <Button
          size="small"
          color="inherit"
          sx={{ mr: 2 }}
          onClick={() => resetActionMode()}
        >
          Cancel
        </Button>
        <LoadingButton
          size="small"
          variant="contained"
          disabled={!formName || !selectedMembers.length > 0}
          loading={saving}
          onClick={() => {
            void handleSave();
          }}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

// -----------------------------------------------------------------------

CDMFileManagerMode.propTypes = {
  dmState: PropTypes.object,
  resetActionMode: PropTypes.func,
};

CDMFileManagerMode.defaultProps = {
  dmState: {},
  resetActionMode: () => null,
};

export default React.memo(CDMFileManagerMode);
