import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/redux/store';
import { shallowEqual } from 'react-redux';
import { setSelectedCertification } from 'src/redux/slices/conversationsV2';

export default function FTSelectCertificationField({
  property,
  isLoading,
  setIsLoading,
}) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const selectedCertification = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedCertification,
    shallowEqual
  );

  const [certificationOptions, setCertificationOptions] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');

  const isUserDashboard = pathname.startsWith('/workbench/user');

  const getCertifications = async () => {
    const getProperty = isUserDashboard
      ? `as_specialist=true&compliance_user=${user?.id}&`
      : `property=${property}&`;

    const response = await axiosInstance.get(
      `certification/certification/?${getProperty}expand=household.household_members.member_type,unit&fields=id,display_name,certification_config,household.name,household.id,household.household_members,unit.id,unit.name&search=${searchFilter}&page_size=5&page=1`
    );

    if (response.status === 200) {
      const certificationDisplayOptions = [];
      response.data.results.forEach((certification) => {
        const option = {
          id: certification.id,
          display_name: `${certification?.household?.name} | ${certification?.display_name}`,
          name: certification?.display_name,
          household_name: certification?.household?.name,
          unit: certification.unit,
          household_id: certification?.household?.id,
          household_members: certification?.household.household_members,
          certificationConfig: certification?.certification_config,
        };
        certificationDisplayOptions.push(option);
      });
      setCertificationOptions(certificationDisplayOptions);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchFilter !== null) {
      const timeOutId = setTimeout(() => {
        setIsLoading(true);
        getCertifications();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCertifications();
  }, []); // eslint-disable-line

  return (
    <>
      <Autocomplete
        fullWidth
        disableClearable
        options={certificationOptions}
        getOptionLabel={(option) => option.display_name}
        renderInput={(params) => (
          <TextField {...params} label="Certification" />
        )}
        value={selectedCertification}
        renderOption={(props, option) => {
          return (
            <Box
              onClick={() => {
                dispatch(setSelectedCertification(option));
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
                px: 2,
                cursor: 'pointer',
                backgroundColor: (theme) =>
                  selectedCertification?.id === option.id &&
                  theme.palette.grey[200],
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <Typography variant={'body1'}>{option.household_name}</Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                {option.name} {option.unit && `• ${option.unit.name}`}
              </Typography>
            </Box>
          );
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, value) => {
          setSearchFilter(value);
        }}
        disabled={isLoading}
        freeSolo
      />
    </>
  );
}
