import { Box, Divider } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import SmsDetailItem from './SmsDetailItem';
import SmsMessageInput from './SmsMessageInput';

export const SMS_LIST_SCROLL_NODE_ID = 'sms-messages-container';

const SmsDetailList = ({ conversation }) => {
  const scrollRef = useRef();
  const messages = useMemo(
    () => [...(conversation?.messages || [])].reverse(),
    [conversation?.messages]
  );

  useEffect(() => {
    if (!messages?.length) {
      return;
    }

    setTimeout(() => {
      const elNode = document.querySelector(
        `#${SMS_LIST_SCROLL_NODE_ID} .simplebar-content-wrapper`
      );
      if (elNode) {
        elNode.scrollTo({ top: elNode.scrollHeight, behavior: 'smooth' });
      }
    }, 1000);
  }, [messages?.length]);

  if (!conversation) {
    return null;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Divider />

      <Scrollbar
        id={SMS_LIST_SCROLL_NODE_ID}
        scrollableNodeProps={{ ref: scrollRef }}
        sx={{ p: 3, flexGrow: 1 }}
      >
        {messages.map((message, i) => (
          <SmsDetailItem
            key={message?.id || i}
            message={message}
            conversation={conversation}
          />
        ))}
      </Scrollbar>

      <SmsMessageInput />
    </Box>
  );
};

export default React.memo(SmsDetailList);
