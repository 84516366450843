import { memo, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// icons
import outlineError from '@iconify/icons-ic/outline-error';
import clock12Filled from '@iconify/icons-fluent/clock-12-filled';
import checkmarkCircle12Filled from '@iconify/icons-fluent/checkmark-circle-12-filled';
import circle12Filled from '@iconify/icons-fluent/circle-12-filled';
// redux
import {
  setSelectedConversation,
  setShouldReload,
  toggleConversationReadStatus,
} from 'src/redux/slices/conversationsV2';
// utils
import axiosInstance from 'src/utils/axios';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import { getCertificationPagePath, getUnitPagePath } from './utils';
// components
import FaxToggleButton from './FaxToggleButton';
import FaxOutInIcon from './FaxOutInIcon';
import { formatPhoneNumber } from 'src/utils/formatPhoneNumber';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 2),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    zIndex: 999,
    position: 'relative',
    boxShadow: theme.customShadows.z24,
  },
}));

const WrapStyle = styled(Link)(({ theme }) => ({
  minWidth: 0,
  display: 'flex',
  transition: theme.transitions.create('padding'),
}));

// ----------------------------------------------------------------------

const FaxItem = ({
  fax: conversation,
  isDense,
  isSelected,
  created,
  propertyName,
  isUserDashboard,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const from = conversation.contact_fax_number || conversation?.from[0];

  const retryFax = async () => {
    try {
      const response = await axiosInstance.post(
        `communication/new_conversation/conversation/retry_send_fax/`,
        { conversation: conversation.id }
      );
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar('The fax is being sent.', { variant: 'info' });
        dispatch(setShouldReload(true));
      }
    } catch (e) {
      enqueueSnackbar('There was an error sending the fax.', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const showSnackbar = useCallback(
    (msg, variant = 'success') => {
      closeSnackbar();
      enqueueSnackbar(msg, { variant });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const markFax = () => {
    dispatch(
      toggleConversationReadStatus({
        conversationId: conversation?.id,
        readValue: !conversation?.isUnread,
        errFunc: () => showSnackbar('Something went wrong.', 'error'),
      })
    );
  };

  const STATUS = {
    unread: {
      id: 0,
      tooltip: 'Mark as Read',
      handleChange: () => {
        markFax();
      },
    },
    pending: {
      id: 1,
      tooltip: 'Pending to Send',
      icon: clock12Filled,
      color: theme.palette.warning.main,
    },
    failed: {
      id: 2,
      tooltip: 'Fax Failed',
      icon: outlineError,
      color: theme.palette.error.main,
      handleChange: () => {
        retryFax();
      },
    },
    sent: {
      id: 3,
      tooltip: 'Successfully Sent',
      icon: checkmarkCircle12Filled,
      color: theme.palette.info.main,
    },
    read: {
      id: 4,
      tooltip: 'Mark as Unread',
      icon: circle12Filled,
      color: theme.palette.primary.main,
      handleChange: () => {
        markFax();
      },
    },
    default: {
      id: 5,
      tooltip: '',
    },
  };

  const selectConversation = useCallback(() => {
    dispatch(
      setSelectedConversation({
        conversation,
        // the unread field is ignored here until the action setSelectedConversation is trigered
        // on the MailDetails COMPONENT
      })
    );
  }, [conversation, dispatch]);

  const getStatus = () => {
    // is unread
    if (!conversation.last_message?.sender_user) {
      if (conversation.isUnread) {
        return STATUS.unread;
      } else {
        return STATUS.read;
      }
    }

    // has failed
    if (
      !!conversation?.documo_metadata?.error_message ||
      conversation?.status === 'failed'
    ) {
      return STATUS.failed;
    }

    //is still sending
    if (
      !conversation?.documo_metadata?.status ||
      conversation?.status === 'pending'
    ) {
      return STATUS.pending;
    }

    // was successfully sent
    if (
      conversation?.documo_metadata?.status === 200 ||
      conversation?.status === 'success'
    ) {
      return STATUS.sent;
    }

    return STATUS.default;
  };

  return (
    <RootStyle
      sx={{
        ...(conversation.isUnread && {
          color: 'text.primary',
          backgroundColor: 'background.paper',
        }),
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          pb: isUserDashboard && propertyName ? 1 : 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            gap: 1,
            paddingTop: theme.spacing(2),
          }}
        >
          <WrapStyle
            color="inherit"
            underline="none"
            component={RouterLink}
            to={`communications/${conversation?.id}${search}`}
            onClick={selectConversation}
            sx={{
              display: 'flex',
              // if more actions are added  the SM width needs to bee updated
              width: '100%',
              flex: 1,
              ...(isDense && { py: 1 }),
            }}
          >
            <FaxOutInIcon
              isIncoming={!conversation.last_message?.sender_user}
              status={conversation.status}
            />

            <Box
              sx={{
                ml: 2,
                minWidth: 0,
                width: '100%',
                alignItems: 'flex-start',
                display: { md: 'flex' },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    pr: 2,
                    minWidth: 200,
                    ...(conversation.isUnread && {
                      fontWeight: 'fontWeightBold',
                    }),
                  }}
                >
                  {`${
                    from?.contact?.first_name ||
                    from?.contact?.displayName ||
                    ''
                  } ${from?.contact?.last_name || ''}`.trim() || 'Unknown'}{' '}
                  {from?.fax_number &&
                    `· ${formatPhoneNumber(from?.fax_number.substring(2))}`}
                </Typography>
                {conversation?.certification?.id ? (
                  <Typography variant={'caption'}>
                    <RouterLink to={getCertificationPagePath(conversation)}>
                      {conversation?.certification?.display_name}
                      <br /> {conversation?.certification?.household?.name}
                    </RouterLink>
                    {conversation?.certification?.household?.unit && (
                      <>
                        ·
                        <RouterLink to={getUnitPagePath(conversation)}>
                          {conversation?.certification?.household?.unit?.name}
                        </RouterLink>
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography variant={'caption'} color={'text.secondary'}>
                    No Related Certification
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'flex-start',
                  mx: 1,
                }}
              >
                <Typography variant={'caption'} color={'text.disabled'}>
                  <b>NUMBER OF PAGES</b>
                </Typography>
                <Typography variant={'body2'} color={'text.primary'}>
                  {conversation?.documo_metadata?.content?.pagesCount ||
                    conversation?.documo_metadata?.pagesCount ||
                    0}
                </Typography>
              </Box>

              {!!conversation?.subject && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'flex-start',
                    mx: 1,
                  }}
                >
                  <Typography variant={'caption'} color={'text.disabled'}>
                    <b>SUBJECT</b>
                  </Typography>
                  <Typography variant={'body2'} color={'text.primary'}>
                    {conversation?.subject}
                  </Typography>
                </Box>
              )}
            </Box>
          </WrapStyle>
          <Typography
            variant="caption"
            sx={{
              flexShrink: 0,
              minWidth: 120,
              ml: 'auto',
              textAlign: 'right',
              ...(conversation.isUnread && { fontWeight: 'fontWeightBold' }),
            }}
          >
            {fDateTimeSuffix(conversation?.createdAt || created)}
          </Typography>

          <FaxToggleButton status={getStatus()} />
        </Box>
        {propertyName && isUserDashboard && (
          <Typography
            variant="caption"
            sx={{
              flexShrink: 0,
              minWidth: 120,
              ml: 'auto',
              textAlign: 'right',
              display: 'flex',
              flexDirection: 'column',
              ...(conversation.isUnread && { fontWeight: 'fontWeightBold' }),
            }}
          >
            {propertyName && isUserDashboard ? propertyName : ''}
          </Typography>
        )}
      </Box>
    </RootStyle>
  );
};

export default memo(FaxItem);

FaxItem.propTypes = {
  mail: PropTypes.object.isRequired,
  isDense: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
};
