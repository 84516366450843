import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import Label from '../Label';
import AttachFileMItem from './AttachFileMItem';
import { getFlattenedArrayByOrderOfAppearance } from '../Compliance/Certification/FileManager/utils';

export default function AttachFileManager({
  certificationFile,
  setFieldValue,
  values,
  touched,
  errors,
}) {
  const [filesOptions, setFilesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = (ix) => {
    const selectedFiles = cloneDeep(values.files);
    selectedFiles.splice(ix, 1);
    setFieldValue('files', selectedFiles);
  };

  useEffect(() => {
    setIsLoading(true);
    const getFiles = async () => {
      const { data } = await axiosInstance.get(
        `certification/document/?certification_file=${certificationFile}&expand=household_members&fields=id,title,answer_group_subject,upper_children_certification_documents,lower_children_certification_documents,household_members.last_name,household_members.first_name,file_url,household_members.id`
      );
      if (data.length) {
        // prepare data to be included in the files dropdown
        const flattenedData = getFlattenedArrayByOrderOfAppearance(data);
        setFilesOptions(flattenedData);
        setIsLoading(false);
      }
    };
    getFiles();
  }, [certificationFile]);

  const handleChange = async (event, value) => {
    try {
      const blob = await fetch(value[0].file_url).then((resp) => {
        if (resp.ok) {
          return resp.blob();
        } else {
          return resp.json();
        }
      });

      const file = new File(
        [blob],
        value[0].title.replace(/[^A-Z0-9]+/gi, '_') + '.pdf'
      );

      setFieldValue('files', [...values.files, { ...value[0], file }]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      <Autocomplete
        fullWidth
        isOptionEqualToValue={(opt, val) => {
          return +val?.id === +opt?.id;
        }}
        loading={isLoading}
        value={[]}
        getOptionDisabled={(option) =>
          !!values.files.find((file) => file.id === option.id)
        }
        multiple
        options={filesOptions}
        noOptionsText={'There are no files in File manager.'}
        filterOptions={(x, state) => {
          if (state.inputValue) {
            return x.filter((item) =>
              item.title.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          } else {
            return x;
          }
        }}
        getOptionLabel={(opt) => {
          return opt?.title || '';
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  px: 1,
                  py: 0.5,
                }}
              >
                <Typography variant={'body1'}>{option.title}</Typography>
                {option.answer_group_subject && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Label variant="ghost" color={'default'}>
                      {option.answer_group_subject}
                    </Label>
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {option.household_members.map((member) => (
                    <Label variant="ghost" color={'default'} key={member?.id}>
                      {member.first_name} {member.last_name}
                    </Label>
                  ))}
                </Box>
                <Typography variant={'caption'} color={'text.secondary'}>
                  {option.name} {option.unit && `• ${option.unit.name}`}
                </Typography>
              </Box>
            </li>
          );
        }}
        onChange={handleChange}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              size="small"
              placeholder={'Select from File Manager'}
            />
            {Boolean(touched.files && errors.files) && (
              <FormHelperText sx={{ pl: 3 }} error>
                {errors.files}
              </FormHelperText>
            )}
          </>
        )}
      />
      {!!values.files.length && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="caption" color={'text.secondary'}>
            <b>FILES</b>
          </Typography>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {values.files.map((file, ix) => (
              <AttachFileMItem
                key={file.id || file.name || 'attach-file-' + ix}
                file={file.file || file}
                index={ix}
                handleDelete={handleDelete}
              />
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
